<template>
    <div class="views__container">
        <div class="views__container--head">
            ミュージアムショップ
            <a v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="router($event, '/')" class="back__btn linkElm">
                <div class="back__btn--icon">
                <img class="back__btn--icon--img" src="../..//public/icon/svg/home.svg" alt="">
                </div>
                <div class="back__btn--title">TOPへ戻る</div>
            </a>
        </div>
        <div class="views__container--inner">
            <div class="minPage__container">
                <div class="minPage__block">
                    <div class="minPage__block--img--box">
                        <div class="minPage__block--img--box--left">
                            <img class="minPage__block--img" src="https://stdichigeki.com/signage/wp-content/uploads/2021/08/shop1.jpg" alt="">
                        </div>
                        <div class="minPage__block--img--box--right">
                            <img class="minPage__block--img" src="https://stdichigeki.com/signage/wp-content/uploads/2021/08/shop2.jpg" alt="">
                            <img class="minPage__block--img" src="https://stdichigeki.com/signage/wp-content/uploads/2021/08/museumshop-logo.jpg" alt="">
                        </div>
                    </div>
                    <p class="minPage__block--content--p no-margin-bottom">
                        普段の生活が豊かで楽しいものになるワクワクするような素敵な商品を揃えています。<br
                        >世界の巨匠のアートグッズを始め、書籍、日用雑貨など充実の内容です。<br
                        >ニューヨーク近代美術館（MoMA）の商品や、宮崎在住の若手作家の作品も販売しております。<br
                        >お気軽にお寄り下さい。
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import router from '../router'
import { touchEvent, linkEvent } from '../mixin02.js'
export default {
    data() {
        return {
            
        }
    },
    mixins: [touchEvent, linkEvent],
    methods: {
        router($event, path) {
            if(!this.touchEnd($event)) return;
            router.push(path);
        },
    },
}
</script>

<style>
.minPage__block--img--box {
    margin: 0 0 45px 0;
    display: flex;
    gap: 0 20px;
}
.minPage__block--img--box--left {
    width: 734px;
}
.minPage__block--img--box--right {
    display: flex;
    flex-direction: column;
    gap: 20px 0;
}
.minPage__block--img {
    display: block;
    width: 100%;
    height: auto;
}
.minPage__block--content--p.no-margin-bottom {
    margin-bottom: 0;
}

</style>