<template>
    <div v-on:touchend.stop="fullAddcloseModal" class="interactiveMovie overlay">
        <div class="interactiveMovie__container--outer" v-if="val" v-bind:class="{closeAdd: classtoggle}">
            <div class="interactiveMovie__container--full">
                <div class="Close__btn">
                    <span class="Close__btn--line a"></span>
                    <span class="Close__btn--line b"></span>
                </div>
                <div class="interactiveMovie__video--box">
                    <div class="interactiveMovie__content--box">
                        <video v-if="val.type=='video'" v-on:ended="fullAddcloseModal()" v-bind:src="val.src" type="video/mp4" ref="fullPageAdd__video" class="interactiveMovie__video" preload="" muted playsinline webkit-playsinline poster="../../public/img/touchselect-02.png"></video>
                        <img v-if="val.type=='img'" ref="fullPageAdd__img" class="interactiveMovie__img" v-bind:src="val.src" alt="">
                        <iframe v-if="val.type=='html'" ref="fullPageAdd__iframe" class="interactiveMovie__iframe" v-bind:src="val.src" frameborder="0" v-on:onload="onload"></iframe>
                    </div>
                    <div class="interactiveMovie__qr--box">
                        <div class="interactiveMovie__qr--text">
                            QRを読み込んでスマートフォンでもお楽しみいただけます。
                        </div>
                        <div class="interactiveMovie__qr">
                            <img v-bind:src="val.qrSrc" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'interactiveMovieSample',
    data() {
        return {
            classtoggle: true,
            ModalToggle: false,
            toggle: "",
            type:  "",
            timeSet:"",
        }
    },
    components: {
    },
    props: ['val'],
    watch: {
    },
    methods: {
        fullAddToggle() {
            setTimeout(() => {
                this.type = this.val.type;
                this.custamAddOpen();
            }, 10);
        },
        custamAddOpen() {
            if (this.val.type === "video"){
                this.classtoggle = false;
                this.videoStart();
                return;
            } else if(this.val.type === "html") {
                this.htmlLoad();
                return;
            }
        },
        onload() {
            console.log("onload");
        },
        htmlLoad() {
            setTimeout(() => {
                setTimeout(() => {
                    this.classtoggle = false;
                }, 1000);
            }, 10);
        },
        videoStart() {
            setTimeout(() => {
                this.$refs.fullPageAdd__video.play();
                this.$refs.fullPageAdd__video.muted = false;
            }, 1000);
        },
        fullAddcloseModal() {
            this.$emit('interactiveMovieClose', this.ModalToggle);
            if (this.val.type === "video"){
                this.$refs.fullPageAdd__video.pause();
                this.$refs.fullPageAdd__video.muted = true;
                this.$refs.fullPageAdd__video.currentTime=0;
            }
            this.classtoggle = true;
        },
    },
    created() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    beforeDestroy() {
    }
}
</script>
<style>
.interactiveMovie.overlay {
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
}
.interactiveMovie__container--outer {
    width: 100%;
    height: 100%;
    transition: all 0.1s ease-out;
}
.interactiveMovie__container--full {
    width: 100%;
    height: 100%;
    position: relative;
}
.interactiveMovie__video--box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px 0;
    align-items: center;
    justify-content: center;
}
.interactiveMovie__content--box {
    width: 100%;
    max-width: 1000px;
    /* margin: 200px auto 0; */
    height: auto;
    background-color: none;
    position: relative;
}
.interactiveMovie__content--box::before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 56.3%;
}
.interactiveMovie__video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.interactiveMovie__img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.interactiveMovie__iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.interactiveMovie__back--btn {
    width: 100%;
    height: 100px;
    text-align: center;
    line-height: 100px;
    background-color: #fff;
    font-weight: 600;
    font-size: 3.0rem;
    color: var(--mainColor);
}
.interactiveMovie__iframe {
    width: 100%;
    margin: 0 auto;

}
.interactiveMovie__overlay--top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: block;
}
.interactiveMovie__overlay--top.addDisplay {
    display: none;
}
.interactiveMovie__qr--box {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
}
.interactiveMovie__qr--text {
    padding: 0 10px 0 38px;
    font-size: 2.0rem;
    font-weight: 500;
}
.interactiveMovie__qr {
    width: 140px;
    height: 140px;
}
.interactiveMovie__qr img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}


.Close__btn {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 380px;
    left: 50%;
    transform: translate(-50%, 0);
    box-sizing: border-box;
    border-radius: 100%;
    background-color: rgba(0,0,0,0.8);
    opacity: 0.9;
}
.Close__btn--line {
    display: block;
    width: 70px;
    height: 8px;
    border-radius: 5px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
}
.Close__btn--line.a {
    transform: translate(-50%,-50%) rotate(45deg);
}
.Close__btn--line.b {
    transform: translate(-50%,-50%) rotate(-45deg);
}
</style>