<template>
    <div v-on:touchend.stop="fullAddcloseModal" class="fulladd overlay">
        <div class="fulladd__container--outer" v-if="val" v-bind:class="{closeAdd: classtoggle}">
            <div class="fulladd__container--full">
                <div class="fulladd__video--box">
                    <div class="custamAdd__box fulladd">
                        <video v-if="val.type=='video'" v-on:ended="fullAddcloseModal()" v-bind:src="val.src" type="video/mp4" ref="fullPageAdd__video" class="fulladd__video" preload="" muted playsinline webkit-playsinline poster="../../public/img/touchselect-02.png"></video>
                        <img v-if="val.type=='img'" ref="fullPageAdd__img" class="fulladd__img" v-bind:src="val.src" alt="">
                        <iframe v-if="val.type=='html'" ref="fullPageAdd__iframe" class="fulladd__iframe" v-bind:src="val.src" frameborder="0" v-on:onload="onload"></iframe>
                    </div>
                </div>
            </div>
            <div class="Close__btn--ad">
                <span class="Close__btn--line--ad a"></span>
                <span class="Close__btn--line--ad b"></span>
            </div>
            <div class="fulladd__back--btn">
                画面をタッチして操作を開始してください
            </div>
        </div>
        <div class="fulladd__overlay--top linkElm" v-on:touchstart="addClass($event)" v-bind:class="{addDisplay: classtoggle}"></div>
    </div>
</template>
<script>
import { linkEvent } from '../mixin02.js'
export default {
    name: 'fulladdSample',
    data() {
        return {
            classtoggle: true,
            ModalToggle: false,
            toggle: "",
            type:  "",
            timeSet:"",

            pageOn: true,
        }
    },
    mixins: [linkEvent],
    components: {
    },
    props: ['val'],
    watch: {
    },
    methods: {
        fullAddToggle() {
            this.pageOn = true;
            setTimeout(() => {
                this.type = this.val.type;
                this.custamAddOpen();
            }, 10);
        },
        custamAddOpen() {
            if (this.val.type === "video"){
                // this.classtoggle = false;
                // this.videoStart();
                this.videoLoad();
                return;
            } else if(this.val.type === "html") {
                this.htmlLoad();
                return;
            }
        },
        onload() {
            console.log("onload");
        },
        htmlLoad() {
            setTimeout(() => {
                setTimeout(() => {
                    this.classtoggle = false;
                }, 1000);
            }, 10);
        },
        videoLoad() {
            this.$refs.fullPageAdd__video.addEventListener('canplaythrough', this.videoStart);
        },
        videoStart() {
            this.$refs.fullPageAdd__video.removeEventListener('canplaythrough', this.videoStart);
            setTimeout(() => {
                if(!this.pageOn) return;
                this.classtoggle = false;
                this.$refs.fullPageAdd__video.currentTime = 0.01;
                setTimeout(() => {
                    if(!this.pageOn) return;
                    this.$refs.fullPageAdd__video.play();
                    this.$refs.fullPageAdd__video.muted = false;
                }, 500);
            }, 500);
        },
        fullAddcloseModal() {
            this.$emit('fulladdClose', this.ModalToggle);
            this.pageOn = false;
            if (this.val.type === "video"){
                this.$refs.fullPageAdd__video.pause();
                this.$refs.fullPageAdd__video.muted = true;
                this.$refs.fullPageAdd__video.currentTime=0;
                this.$refs.fullPageAdd__video.removeEventListener('canplaythrough', this.videoStart);
            }
            this.classtoggle = true;
        },
    },
    created() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    beforeDestroy() {
    }
}
</script>
<style>
.fulladd.overlay {
    /* background-color: #fff; */
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
}
.fulladd__container--outer {
    width: 100%;
    height: 100%;
    transition: all 0.1s ease-out;
}
.fulladd__container--full {
    width: 100%;
    height: calc(100% - 100px);
}
.fulladd__video--box {
    width: 100%;
    height: 100%;
}
.custamAdd__box.fulladd {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}
.fulladd__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.fulladd__img {
    width: 100%;
    height: 100%;
}
.fulladd__iframe {
    width: 100%;
    height: 100%;
}
.fulladd__back--btn {
    width: 100%;
    height: 100px;
    text-align: center;
    line-height: 100px;
    background-color: #fff;
    font-weight: 600;
    font-size: 3.0rem;
    color: var(--mainColor);
}
/*  */
.fulladd__iframe {
    /* width: 949.5px; */
    width: 1006px;
    margin: 0 auto;

}
.fulladd__overlay--top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: block;
}
.fulladd__overlay--top.addDisplay {
    display: none;
}


.Close__btn--ad {
    width: 100px;
    height: 100px;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translate(-50%, 0);
    box-sizing: border-box;
    border-radius: 100%;
    background-color: rgba(0,0,0,0.8);
    opacity: 0.9;
}
.Close__btn--line--ad {
    display: block;
    width: 70px;
    height: 8px;
    border-radius: 5px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
}
.Close__btn--line--ad.a {
    transform: translate(-50%,-50%) rotate(45deg);
}
.Close__btn--line--ad.b {
    transform: translate(-50%,-50%) rotate(-45deg);
}
</style>