<template>
    <div v-on:touchend.self="closeModal" class="overlay">
        <div class="mainContent__list topScheduleModal">
            <div class="mainContent__list--head modal">
                本日のスケジュール
                <div v-on:touchend="closeModal" class="modal__close--btn linkElm" v-on:touchstart="addClass($event)">
                    <span class="modal__close--btn--inner modal__close--btn--inner01"></span>
                    <span class="modal__close--btn--inner modal__close--btn--inner02"></span>
                </div>
            </div>
            <div class="mainContent__list--content--outer--model">
                <div class="sheduleModal__content">
                    <div class="sheduleModal__content--left">
                        <img v-bind:src="val.thum" class="sheduleModal__content--left--img">
                    </div>
                    <div class="sheduleModal__content--right">
                        <div class="sheduleModal__content--right--inner">
                            <div v-html="val.title" class="sheduleModal__content--title modal"></div>
                            <div v-html="val.content" class="sheduleModal__content--text modal"></div>
                            <div class="sheduleModal__content--detail--outer">
                                <div class="sheduleModal__content--detail">
                                    <div class="sheduleModal__content--detail--title">
                                        開催期間
                                    </div>
                                    <div class="sheduleModal__content--detail--inner">
                                        <div v-html="val.customFieldPeriod" class="sheduleModal__content--detail--text"></div>
                                    </div>
                                </div>
                                <div class="sheduleModal__content--detail">
                                    <div class="sheduleModal__content--detail--title">
                                        展示会場
                                    </div>
                                    <div class="sheduleModal__content--detail--inner">
                                        <div class="sheduleModal__content--detail--text">
                                            <span v-for="item in val.customFieldVenue" v-bind:key="item" v-html="item" class="sheduleModal__content--detail--text--array"></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="sheduleModal__content--detail">
                                    <div class="sheduleModal__content--detail--title">
                                        入場料金
                                    </div>
                                    <div class="sheduleModal__content--detail--inner">
                                        <div v-if="!val.customFieldPrice00" class="sheduleModal__content--detail--text">
                                            <table class="sheduleModal__content--detail--table">
                                                <tr class="sheduleModal__content--detail--tr">
                                                    <th class="sheduleModal__content--detail--th"></th>
                                                    <th class="sheduleModal__content--detail--th">当日</th>
                                                    <th class="sheduleModal__content--detail--th">団体割引</th>
                                                </tr>
                                                <tr class="sheduleModal__content--detail--tr">
                                                    <th class="sheduleModal__content--detail--th">一般</th>
                                                    <td v-html="val.customFieldPrice01" class="sheduleModal__content--detail--td"></td>
                                                    <td v-html="val.customFieldPrice03" class="sheduleModal__content--detail--td"></td>
                                                </tr>
                                                <tr class="sheduleModal__content--detail--tr">
                                                    <th class="sheduleModal__content--detail--th">小中学生</th>
                                                    <td v-html="val.customFieldPrice02" class="sheduleModal__content--detail--td"></td>
                                                    <td v-html="val.customFieldPrice04" class="sheduleModal__content--detail--td"></td>
                                                </tr>
                                            </table>
                                            <div class="sheduleModal__content--detail--table--undertext">
                                                <p>※団体は20名以上</p>
                                            </div>
                                        </div>
                                        <div v-if="val.customFieldPrice00" class="sheduleModal__content--detail--text">
                                            <p v-html="val.customFieldPrice00text"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { linkEvent } from '../mixin02.js'
export default {
    name: 'topScheduleModal',
    data() {
        return {
            ModalToggle: false,
        }
    },
    mixins: [linkEvent],
    props: ['val'],
    created() {
    },
    methods: {
        closeModal() {
            this.$emit('scheduleModalClose', this.ModalToggle);
        }
    }
}
</script>
<style>
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 1080px;
    min-width: 100%;
    height: 1920px;
    min-height: 100%;
    background-color: rgba(0,0,0,0.3);
}
.topScheduleModal {
    width: 90% !important;
    height: auto;
    min-width: 1000px;
    background-color: #fff;
    border-radius: 5px;
    position: absolute;
    top: 625px;
    left: 50%;
    transform: translate(-50%, 0);
}
.mainContent__list--content--modal {

}
.mainContent__list--content--outer--model {
    padding: 30px;
}
.mainContent__list--head.modal {
    position: relative;
}
.modal__close--btn {
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: var(--headerTextColor);
    border-radius: 50%;
    top: 50%;
    right: 8px;
    transform: translate(0,-50%);
}
.modal__close--btn--inner {
    display: block;
    width: 25px;
    height: 3px;
    border-radius: 3px;
    background-color: var(--mainColor);
    position: absolute;
    top: 50%;
    left: 50%;
}
.modal__close--btn--inner01 {
    transform: translate(-50%,-50%) rotate(-45deg);
}
.modal__close--btn--inner02 {
transform: translate(-50%,-50%) rotate(45deg);
}
.sheduleModal__content {
    display: flex;
    align-items: flex-start;
    gap: 0 30px;
}
.sheduleModal__content--left {
    width: 40%;
}
.sheduleModal__content--left--img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.sheduleModal__content--right {
    width: 60%;
}
.sheduleModal__content--right--inner {

}
.sheduleModal__content--title.modal {
    font-size: 2.9rem;
    font-weight: 500;
}
.sheduleModal__content--text.modal {
    font-size: 1.5rem;
    text-align: justify;
    margin: 20px 0 0 0;
    font-weight: 500;
}
.sheduleModal__content--text.modal p {
    line-height: 1.5;
    margin: 0 0 0.8em;
}
.sheduleModal__content--detail--outer {
    margin: 40px 0 0 0;
}
.sheduleModal__content--detail {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0 0 40px 0;
}
.sheduleModal__content--detail:nth-last-child(1) {
    margin-bottom: 0;
}
.sheduleModal__content--detail--title {
    font-weight: 600;
}
.sheduleModal__content--detail--inner {
    margin: 8px 0 0 0;
    padding: 15px 0;
    border-top: solid 1px #DFDFDF;
    border-bottom: solid 1px #DFDFDF;
}
.sheduleModal__content--detail--text {
    
}
.sheduleModal__content--detail--text p {

}
.sheduleModal__content--detail--text--array::after {
    content: "、";
}
.sheduleModal__content--detail--text--array:nth-last-child(1)::after {
    content: "";
}
.sheduleModal__content--detail--table {
    width: 100%;
    border-collapse: collapse;
}
.sheduleModal__content--detail--tr {
    text-align: center;
    height: 40px;
}
.sheduleModal__content--detail--th {
    text-align: center;
    background-color: #DEDEDE;
    border: solid 1px #EEEEEE;
}
.sheduleModal__content--detail--td {
    text-align: center;
    background-color: #FAFAFA;
    border: solid 1px #EEEEEE;
}
.sheduleModal__content--detail--td::after {
    content: "円";
}
.sheduleModal__content--detail--table--undertext {
    margin: 8px 0 0 0;
}
</style>