<template>
    <div v-on:touchend.stop="fullAddcloseModal" class="fullPageAdd overlay" v-bind:class="{closeAdd: classtoggle}">
        <div class="fullPageAdd__container--outer" v-if="val">
            <div class="fullPageAdd__container">
                <div class="fullPageAdd__video--box">
                    <video v-on:ended="normalVideoEnd()" ref="fullPageAdd__video" class="fullPageAdd__video" src="../../public/video/sample11.mp4" type="video/mp4" preload="" muted playsinline webkit-playsinline poster="../../public/img/touchselect-02.png"></video>
                    <div class="custamAdd__box" v-bind:class="{closeAdd: custamClasstoggle}">
                        <video v-if="type=='video'" v-on:ended="fullAddcloseModal()" v-bind:src="fullAddsData.dataUrl" type="video/mp4" ref="custamAdd__video" class="fullPageAdd__video" preload="" muted playsinline webkit-playsinline poster="../../public/img/touchselect-02.png"></video>
                        <img v-if="type=='img'" ref="custamAdd__img" class="fullPageAdd__img" v-bind:src="fullAddsData.dataUrl" alt="">
                        <iframe v-if="type=='html'" ref="custamAdd__iframe" class="fullPageAdd__iframe" v-bind:src="fullAddsData.dataUrl" frameborder="0"></iframe>
                    </div>
                </div>
            </div>
            <div class="fullPageAdd__back--btn">画面をタッチして操作を開始してください</div>
        </div>
        <div class="fullPageAdd__overlay--top linkElm" v-on:touchstart="addClass($event)"></div>
    </div>
</template>

<script>
import { HOST } from '../mixin.js'
import { linkEvent } from '../mixin02.js'
export default {
    name: 'fullPageAdd',
    data() {
        return{
            classtoggle: true,
            custamClasstoggle: true,
            ModalToggle: false,
            fullAddsData: [],
            toggle: "",
            type:  "",
            timeSet:"",

            pageOn: true,
        }
    },
    mixins: [linkEvent],
    props: ['val'],
    watch: {
        val: function(newVal) {
            this.normalVideoLoad(newVal);
            this.getFullAdds(newVal);
        }
    },
    methods: {
        normalVideoLoad(val) {
            if(!val) return;
            this.pageOn = true;
            setTimeout(() => {
                this.$refs.fullPageAdd__video.addEventListener('canplaythrough', this.videoStart(this.$refs.fullPageAdd__video));
            }, 10);
        },
        getFullAdds(val) {
            if(!val) return;
            this.pageOn = true;
            this.$axios
                .get(HOST + '/wp-json/wp/v2/pages/73?_embed')
                .then( response => {
                    this.fullAddsData.id = response.data.id;
                    this.fullAddsData.toggle = response.data.acf.fulladdtoggle;
                    this.fullAddsData.dataType = response.data.acf.fulladdcate;
                    this.fullAddsData.dataUrl = response.data.acf.fulladdurl;
            });
        },
        videoStart(_elm) {
            _elm.currentTime = 0.01;
            setTimeout(() => {
                this.classtoggle = false;
                setTimeout(() => {
                    if(!this.pageOn) return;
                    _elm.play();
                    _elm.muted = false;
                }, 800);
            }, 1500);
        },
        custamVideoStart(_elm) {
            _elm.currentTime = 0.01;
            setTimeout(() => {
                this.custamClasstoggle = false;
                setTimeout(() => {
                    if(!this.pageOn) return;
                    _elm.play();
                    _elm.muted = false;
                }, 500);
            }, 1000);
        },
        normalVideoEnd() {
            if(this.fullAddsData.toggle){
                this.type = this.fullAddsData.dataType;
                this.custamAddOpen();
                return;
            }
            setTimeout(() => {
                this.fullAddcloseModal();
            }, 500);
        },
        custamAddOpen() {
            if (this.fullAddsData.dataType === "video"){
                this.videoLoad();
                return;
            } else if(this.fullAddsData.dataType === "html") {
                this.htmlLoad();
                return;
            } else if(this.fullAddsData.dataType === "img") {
                this.imgLoad();
                return;
            }
        },
        htmlLoad() {
            setTimeout(() => {
                this.$refs.custamAdd__iframe.onload = () => {
                    setTimeout(() => {
                        this.custamClasstoggle = false;
                        this.timer(15000);
                    }, 1600);
                };
            }, 0);
        },
        imgLoad() {
            setTimeout(() => {
                this.$refs.custamAdd__img.onload = () => {
                    this.custamClasstoggle = false;
                    this.timer(15000);
                };
            }, 0);
        },
        videoLoad() {
            setTimeout(() => {
                this.$refs.custamAdd__video.addEventListener('canplaythrough', this.custamVideoStart(this.$refs.custamAdd__video));
            }, 0);
        },
        timer(time) {
            if(time === false) {
                clearTimeout(this.timeSet);
                return;
            }
            this.timeSet = setTimeout(this.fullAddcloseModal, time);
        },

        fullAddcloseModal() {
            this.classtoggle = true;
            setTimeout(() => {
                this.$emit('addModalClose', this.ModalToggle);
                if (this.fullAddsData.dataType === "video"){
                    this.$refs.fullPageAdd__video.pause();
                    this.$refs.fullPageAdd__video.muted = true;
                    this.$refs.fullPageAdd__video.currentTime=0;
                }
                this.pageOn = false;
                this.custamClasstoggle = true;
                this.fullAddsData = [];
                this.toggle = "";
                this.timer(false);
                this.type = "";
            }, 150);
        },
    },
    created() {
    },
    mounted() {
    },
    beforeUpdate() {
    }
}
</script>

<style>
.fullPageAdd {
    width: 100%;
    height: 1920px;
    min-height: 100%;
    transition: all 0.1s ease-out;
}
.fullPageAdd__container--outer {
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255);
    color: var(--mainColor);
}
.fullPageAdd__container {
    width: 100%;
    /* height: calc(100% - 100px); */
    height: 100%;

}
.fullPageAdd__back--btn {
    height: 100px;
    text-align: center;
    line-height: 100px;
    background-color: #fff;
    font-weight: 600;
    font-size: 3.0rem;

    display: none;
}
.fullPageAdd__video--box {
    width: 100%;
    height: 100%;
    position: relative;
}
.fullPageAdd__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.1s ease-out;
    position: absolute;
    top: 0;
    left: 0;
}
.custamAdd__box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    transition: all 0.1s ease-out;
}
.custamAdd__box .fullPageAdd__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.1s ease-out;
}
.custamAdd__box .fullPageAdd__img,
.custamAdd__box .fullPageAdd__iframe {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: all 0.1s ease-out;
}


.closeAdd {
    opacity: 0.0;
}

.fullPageAdd.overlay .fullPageAdd__overlay--top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}

</style>