<template>
  <div>
    <div class="publicTransport views__container">
      <div class="views__container--head">
        公共交通機関
        <a v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="router($event, '/')" class="back__btn linkElm">
          <div class="back__btn--icon">
            <img class="back__btn--icon--img" src="../..//public/icon/svg/home.svg" alt="">
          </div>
          <div class="back__btn--title">TOPへ戻る</div>
        </a>
      </div>
      <div class="views__container--inner">
        <div class="PTviews__container">
          <!-- btnBox -->
          <div class="PTviews__btn--box">
            <input type="radio" v-model="toggle" name="PTviews__btn" value="busTimeTable" id="bus" class="PTviews__btn--radio" checked="checked">
            <label for="bus" class="PTviews__btn linkElm" v-on:touchstart="addClass($event)">
              <span class="PTviews__btn--icon bus"></span>
              <span class="PTviews__btn--title">バ ス</span>
            </label>
            <input type="radio" v-model="toggle" name="PTviews__btn" value="trainTimeTable" id="train" class="PTviews__btn--radio">
            <label for="train" class="PTviews__btn linkElm" v-on:touchstart="addClass($event)">
              <span class="PTviews__btn--icon train"></span>
              <span class="PTviews__btn--title">電 車</span>
            </label>
          </div>
          <!-- btnBox end -->
          <div class="PTviews__content--outer">
            <component v-on:busModalOpen="viewModelOpen" v-bind:is="toggle"></component>
          </div>
        </div>
      </div>
    </div>
    <!-- modal -->
    <busTimeTableModal v-on:busModalClose="viewModalClose" v-bind:val="busData" v-show="busShowToggle"></busTimeTableModal>
  </div>
</template>

<script>
import router from '../router'
import trainTimeTable from '../components/trainTimeTable.vue'
import busTimeTable from '../components/busTimeTable'
import busTimeTableModal from '../components/busTimeTableModal'
import { touchEvent, linkEvent } from '../mixin02.js'

export default {
  data() {
    return{
      toggle: "busTimeTable",
      busShowToggle: false,
      busData: "",
    }
  },
  mixins: [touchEvent, linkEvent],
  components: {
    trainTimeTable,
    busTimeTable,
    busTimeTableModal,
  },
  methods: {
    router($event, path) {
      if(!this.touchEnd($event)) return;
      router.push(path);
    },
    viewModelOpen(toggle, item) {
      this.busShowToggle = toggle;
      this.busData = item;
    },
    viewModalClose(toggle) {
      this.busShowToggle = toggle;
    },
  }
}
</script>

<style>
.PTviews__container {
  padding: 60px 0;
  margin: 0 auto;
  width: 1000px;
}
.PTviews__btn--box {
  display: flex;
  justify-content: center;
  gap: 0 60px;
  margin: 0 0 60px 0;
}
.PTviews__btn--radio {
  display: none;
}
.PTviews__btn {
  width: 300px;
  height: 66px;
  border-radius: 66px;
  position: relative;
  background-color: #EBEBEB;
  display: flex;
  align-items: center;
  justify-content: center;
}
.PTviews__btn--icon {
  display: block;
  width: 24px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: no-repeat center center / contain;
}
.PTviews__btn--icon svg {
  fill: #4B4B4B;
}
.PTviews__btn--icon.train {
  background-image: url();
}
.PTviews__btn--icon.bus {
  background-image: url();
}
.PTviews__btn--title {
  font-size: 2.1rem;
  font-weight: 600;
  color: #4B4B4B;
  padding-top: 0.1em;
}
/* active */
.PTviews__btn--radio:checked + .PTviews__btn {
  background-color: var(--mainColor
  );
}
.PTviews__btn--radio:checked + .PTviews__btn .PTviews__btn--icon {
  fill: #fff;
}
.PTviews__btn--radio:checked + .PTviews__btn .PTviews__btn--title {
  color: #fff;
}
/* active end */
.PTviews__content--outer {
  width: 100%;
  height: auto;
  border: solid 3px var(--mainColor);
  padding: 60px 40px;
  border-radius: 10px;
}
</style> 