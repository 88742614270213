<template>
  <div class="floorMap views__container">
    <div class="views__container--head">
      フロアマップ
      <a v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="router($event, '/')" class="back__btn linkElm">
        <div class="back__btn--icon">
          <img class="back__btn--icon--img" src="../..//public/icon/svg/home.svg" alt="">
        </div>
        <div class="back__btn--title">TOPへ戻る</div>
      </a>
    </div>
    <div class="views__container--inner floorMap zoom">
      <div class=" floorMap__inner zoom__elm">
        <div class="floorMap__img--box">
          <img class="floorMap__img" src="../../public/img/13views/floor_map.jpeg" alt="">
        </div>
        <div class="floorMap__point--box">
          <input type="radio" name="floorMapPoint" class="floorMap__point--radio" id="floor01" value="コレクション展示室１(1F)" v-model="radioData">
          <label class="floorMap__point--btn floor01 linkElm" for="floor01" v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="scheduleModalOpen($event, 'コレクション展示室１(1F)')">コレクション展示室１(1F)</label>
          <input type="radio" name="floorMapPoint" class="floorMap__point--radio" id="floor02" value="企画展示室１(2F)" v-model="radioData">
          <label class="floorMap__point--btn floor02 linkElm" for="floor02" v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="scheduleModalOpen($event, '企画展示室１(2F)')">企画展示室１(2F)</label>
        </div>
      </div>
    </div>
    <!-- modal -->
    <topScheduleModal v-if="skdData[0]" v-show="modalToggle" v-bind:val="skdData[0]" v-on:scheduleModalClose="scheduleClose"></topScheduleModal>
    <!-- modal end -->
  </div>
</template>

<script>
import router from '../router'
import { HOST } from '../mixin.js'
import panzoom from '../panzoom/'
import { touchEvent, linkEvent } from '../mixin02.js'

// modal
import topScheduleModal from '../components/topScheduleModal'

export default {
  data() {
    return{
      modalToggle: false,
      skdList: [],
      skdData: [],
      radioData: "",

      zoomElm: document.querySelector(".zoom__elm"),
      panZoom: null,
    }
  },
  mixins: [touchEvent, linkEvent],
  components: {
    topScheduleModal,
  },
  methods: {
    router($event, path) {
      if(!this.touchEnd($event)) return;
      router.push(path);
    },
    getSchedule() {
      this.$axios
        .get(HOST + '/wp-json/wp/v2/todeayexhibition?_embed')
        .then( response => {
          for( let item of response.data ) {
            // console.log(item);
            this.skdList.push({
              id: item.id,
              title: item.title.rendered,
              content: item.content.rendered,
              excerpt: item.excerpt.rendered,
              thum: item._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url,
              thumAlt: item._embedded['wp:featuredmedia'][0].alt_text,
              term: item._embedded['wp:term'][0][0].slug,
              customField: item.acf.todayexhibitiontime,
              customFieldPeriod: item.acf.period,
              customFieldVenue: item.acf.venue,
              customFieldPrice00: item.acf.price00,
              customFieldPrice00text: item.acf.price00text,
              customFieldPrice01: item.acf.price01,
              customFieldPrice02: item.acf.price02,
              customFieldPrice03: item.acf.price03,
              customFieldPrice04: item.acf.price04,
            })
          }
        })
    },
    scheduleModalOpen($event, value) {
      if(!this.touchEnd($event)) return;
      setTimeout(() => {
        this.skdData = this.skdList.filter((item) => !item.customFieldVenue.indexOf(value),);
        this.modalToggle = true;
      }, 10);
    },
    scheduleClose() {
      this.modalToggle = false;
    },
    zoomOn() {
      setTimeout(() => {
        this.zoomElm = document.querySelector(".zoom__elm");
        this.panZoom = panzoom(this.zoomElm, {
          pinchSpeed: 1.0,
          initialZoom: 1.0,
          maxZoom: 3.0,
          minZoom: 1.0,
          bounds: true,
          boundsPadding: 1.0,
        });
      }, 10);
    },
    zoomOff() {
      this.panZoom.dispose();
    },
  },
  created() {
  },
  mounted() {
    this.getSchedule();
    this.zoomOn();
  },
  destroyed() {
    this.zoomOff();
  }
}
</script>

<style>
.views__container--inner.floorMap {
  position: relative;
}
.floorMap__img--box {
  width: 100%;
  height: 100%;
}
.floorMap__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}


/* 検証 */
.floorMap__point--box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.floorMap__point--radio {
  display: none;
}
.floorMap__point--btn {
  display: block;
  width: 230px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-weight: 600;
  background-color: #ffff00;
  border-radius: 3px;
  position: absolute;
}
.floorMap__point--btn.floor01 {
  top: 11%;
  left: 29%;
}
.floorMap__point--btn.floor02 {
  top: 34%;
  left: 6%;
}
</style>