<template>
    <div class="mainContent__list--components mainContent__list--news--components">
        <div class="mainContent__list--head">本日のニュース</div>
        <div class="mainContent__list--content--outer mainContent__list--content--outer--scroll">
            <div class="mainContent__list--content--outer--scrollBar">
                <div class="mainContent__list--content">
                    <!-- 繰り返し -->
                    <div v-for="newsElement in newsList" v-bind:key="newsElement.id" v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="topNewsModalOpen($event, newsElement)" class="mainContent__list--news--content linkElm">
                        <div v-html="newsElement.title" class="mainContent__list--news--title">
                        </div>
                        <div v-html="newsElement.content" class="mainContent__list--news--text">
                        </div>
                    </div>
                    <!-- 繰り返しここまで -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HOST } from '../mixin.js'
import { touchEvent, linkEvent } from '../mixin02.js'
export default {
    name: 'topNews',
    data() {
        return{
            newsList: [], //投稿格納場所
            newsList02: [], //投稿格納場所
            total: 0, //投稿の総数
            pages: 0, //ページの総数
            currentPage: 1, //初期表示のページかな
            perPage: 8, // ページのマックスの値

            // modal
            modalTogel: false,

        }
    },
    mixins: [touchEvent, linkEvent],
    watch: {
    },
    methods: {
        getNews(obj) {
            this.$axios
                .get( HOST + '/wp-json/wp/v2/posts' )
                .then( response => {
                    let objLength = Math.max(obj.length,response.data.length);
                    for(let i = 0; i <= objLength; i++) {
                        obj.shift();
                    }
                    for(let newsElement of response.data) {
                        obj.push({
                            id: newsElement.id,
                            title: newsElement.title.rendered,
                            content: newsElement.content.rendered,
                        })
                    }
                })
        },
        // 自動更新用
        Json(item) {
            // return JSON.parse(JSON.stringify(item));
            return JSON.stringify(item);
        },
        reload() {
            location.reload(true);
        },
        topNewsModalOpen($event, item) {
            if(!this.touchEnd($event)) return;
            setTimeout(() => {
                this.modalTogel = true;
                this.$emit('topNewsModalOpen', this.modalTogel, item);
            }, 50);
            this.gaEvent(item.title);
        },
        gaEvent(label) {
            this.$ga.event('news_modal_open', 'click', label);
        },
    },
    created: function() {
        // 自動更新用
        this.getNews(this.newsList);
        this.getNews(this.newsList02);
        setTimeout(() => {
            setInterval(() => {
                this.getNews(this.newsList02);
                if ((this.Json(this.newsList) !== this.Json(this.newsList02))) {
                    // this.reload();
                    this.getNews(this.newsList);
                }
            }, 120000); //2分ごとに更新
        }, 1000);
    },
    mounted() {

    },
}

</script>

<style>
/* ニュース */
.mainContent__list--news--content {
    width: 100%;
    background-color: var(--listBgColor);
    border-radius: 3px;
    filter: drop-shadow(0 2px 3px rgba(0,0,0,0.1));
    margin: 0 0 22px 0;
    padding: 20px 25px 16px;
    overflow: hidden;
    position: relative;
}
.mainContent__list--news--content:nth-last-child(1) {
    margin-bottom: 12px;
}
.mainContent__list--news--content::before {
    content: "";
    display: block;
    width: 100%;
    height: 4px;
    background-color: var(--mainColor);
    position: absolute;
    top: 0;
    left: 0;
}
.mainContent__list--news--title {
    font-size: 2.0rem;
    font-weight: 500;
}
.mainContent__list--news--text {
    font-size: 1.5rem;
    text-align: justify;
    margin: 12px 0 0 0;
    font-weight: 500;
}
.mainContent__list--news--text p {
    line-height: 1.5;
}
</style>