import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios' // 追加
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueAnalytics from './vue-analytics';
import { createGtm } from '@gtm-support/vue-gtm';

Vue.use(VueAwesomeSwiper)

 
Vue.prototype.$axios = axios // 追加
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');

Vue.use(createGtm,{
    id: 'GTM-T33W2GN',
    vueRouter: router,
});

Vue.use(VueAnalytics, {
  id: 'UA-207727810-1',
  router
})

Vue.use(VueAnalytics, {
  id: 'G-VGYXCFMCNL',
  router
})
