<template>
  <div class="doods views__container">
    <div class="views__container--head">
      グッズ一覧
      <a v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="router($event, '/')" class="back__btn linkElm">
        <div class="back__btn--icon">
          <img class="back__btn--icon--img" src="../..//public/icon/svg/home.svg" alt="">
        </div>
        <div class="back__btn--title">TOPへ戻る</div>
      </a>
    </div>
    <div class="views__container--inner goods">
      <div class="views__container--inner--bar goods">
        <div class="goods__container">
          <!-- 繰り返し -->
          <div v-for="item in goodsList" v-bind:key="item.id" v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="openModal($event, item)" class="mainContent__list--products--list goods__page linkElm">
              <div class="mainContent__list--products--imgBox">
                  <img v-bind:src="item.thum" v-bind:alt="item.thumAlt" class="mainContent__list--products--img">
              </div>
              <div v-html="item.title" class="mainContent__list--products--title"></div>
              <div v-html="item.customField" class="mainContent__list--products--price"></div>
          </div>
          <!-- 繰り返しここまで -->
        </div>
      </div>
    </div>
    <!-- modal -->
    <topGoodsModal v-on:GoodsModalClose="topGoodsClose" v-bind:toggle="pageGoodsToggle" v-bind:val="pageGoodsData" v-show="pageGoodsToggle" ></topGoodsModal>
    <!-- modal end -->
  </div>
</template>

<script>
import router from '../router'
import { HOST } from '../mixin.js'
import topGoodsModal from '../components/topGoodsModal'
import { touchEvent, linkEvent } from '../mixin02.js'
export default {
    name: 'topGoodsList',
    data() {
        return {
            goodsList: [],

            // modal (グッズ)
            pageGoodsToggle: false,
            pageGoodsData: "",
        }
    },
    mixins: [touchEvent, linkEvent],
    components: {
      // modal
      topGoodsModal,
    },
    methods: {
        router($event, path) {
          if(!this.touchEnd($event)) return;
          router.push(path);
        },
        getGoods() {
            this.$axios
                .get(HOST + '/wp-json/wp/v2/goods-list?per_page=100&_embed')
                .then( response => {
                    for( let item of response.data ) {
                        this.goodsList.push({
                            id: item.id,
                            title: item.title.rendered,
                            content: item.content.rendered,
                            thum: item._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url,
                            thumAlt: item._embedded['wp:featuredmedia'][0].alt_text,
                            customField: item.acf.goodsprice,
                        })
                    }
                })
        },
        topGoodsClose(toggle) {
            this.pageGoodsToggle = toggle;
        },
        // modal
        openModal($event, item) {
          if(!this.touchEnd($event)) return;
          this.pageGoodsToggle = true;
          this.pageGoodsData = item;
        },
    },
    created: function() {
        this.getGoods()
    }
}
</script>

<style>
/* グッズ */
.mainContent__list--products--list {
    width: calc(33.3333% - 16px);
}
.mainContent__list--products--imgBox {
    width: 100%;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    border: solid 1px #f7f7f7;
}
.mainContent__list--products--imgBox::before {
    content: "";
    display: block;
    widows: 100%;
    padding-top: 100%;
}
.mainContent__list--products--img {
    width: 100%;
    height: 100%;
    /* object-fit: contain; */
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}
.mainContent__list--products--title {
    margin: 12px 0 0 0;
    font-size: 1.6rem;
    font-weight: 500;
}
.mainContent__list--products--price {
    margin: 6px 0 0 0;
    font-size: 1.3rem;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 0 2px;
}
.mainContent__list--products--price::before {
    content: "¥";
}
.mainContent__list--products--price::after {
    content: "(税込)";
    font-size: 1.0rem;
}

.views__container--inner.goods {
  padding: 0 10px 30px 0;
}
.views__container--inner--bar.goods {
  width: 100%;
  height: 100%;
  overflow: overlay;
}
.views__container--inner--bar.goods::-webkit-scrollbar {
    width: 12px;
}
.views__container--inner--bar.goods::-webkit-scrollbar-track {
    margin: 3px 0;
}
.views__container--inner--bar.goods::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #D3D3D3;
}
.goods__container {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  padding: 40px 0 0 0;
}
.mainContent__list--products--list.goods__page {
  width: calc(25% - 18px);
}
</style>