<template>
    <div class="headAdArea__container">
        <div class="headAdArea__list" ref="elmBox" id="elmBox">
        </div>
    </div>
</template>

<script>
export default {
    name: 'swiperComp',
    data() {
        return {
            elmBox: "",
            action: false,
            counter: 0,
            timeSet: "",

            curentElm: "",
            beforeElm: "",

            keepData: "",
            pageOn: true,
            // ------------------------------
            itemList: [
                // {
                //     type: 'video',
                //     src: 'video/test001.mp4'
                // },
                {
                    type: 'video',
                    src: 'video/sample15.mp4'
                },
                {
                    type: 'video',
                    src: 'video/sample17.mp4'
                },
                {
                    type: 'video',
                    src: 'video/test002.mp4'
                },
                {
                    type: 'video',
                    src: 'video/test003.mp4'
                },
                {
                    type: 'video',
                    src: 'video/test004.mp4'
                },
            ],
            // ------------------------------
        }
    },
    components: {
    },
    props: ['val'],
    watch: {
        val: function(newval) {
            if(newval) {
                this.mediaRestart(newval)
            }
        },
    },
    computed: {
    },
    methods: {
        mediaInit() {
            this.elmBox = this.$refs.elmBox;
            document.addEventListener("touchstart", this.eventCheck);

            if(this.keepData.counter) this.counter = this.keepData.counter;
            if(this.itemList[this.counter].type === 'html') return this.htmlCreate();
            this.videoCreateOrigin();
        },
        keep() {
            var keeper = {};
            keeper.counter = this.counter,
            keeper.type = this.itemList[keeper.counter].type;
            if(keeper.type === 'video') keeper.time = Math.floor(this.curentElm.currentTime * 10) / 10;

            this.$emit('keepDataExport', keeper);
        },
        keepDataInport(val) {
            this.keepData = val;

            this.mediaInit();
        },
        eventCheck() {
            this.action = true;
        },
        htmlCreate() {
            var htmlElm = document.createElement("iframe");
            htmlElm.className = "topHtml close topHtml" + this.counter;
            htmlElm.src = this.itemList[this.counter].src;

            this.elmBox.appendChild(htmlElm);
            this.htmlLoad(htmlElm);
        },
        htmlLoad(_elm) {
            _elm.onload = () => {
                setTimeout(() => {
                    _elm.classList.remove('close');
                    this.curentElm = _elm;
                    this.timer(15000);
                    setTimeout(() => {
                        if(this.beforeElm) this.elmDelete(this.beforeElm);
                    }, 500);
                }, 3000);
            }
        },
        timer(time) {
            if(time === false) {
                clearTimeout(this.timeSet);
                return;
            }
            this.timeSet = setTimeout(this.mediaEnd, time);
        },
        videoCreateOrigin() {
            var video = document.createElement('video');
            video.src = "";
            video.className = "topVideo close";
            video.muted = false;
            video.setAttribute('playsinline', '');
            video.poster = "./img/touchselect-01.png";
            video.src = this.itemList[this.counter].src;
            video.className += " video" + this.counter;

            this.videoCreate(video);
        },
        videoCreate(_elm) {
            this.elmBox.appendChild(_elm);
            _elm.currentTime = 0.01;
            this.videoLoad(_elm);
            if(this.keepData.time) return _elm.currentTime = this.keepData.time;
        },
        elmDelete(_elm) {
            if(this.elmBox) this.elmBox.removeChild(_elm);
        },
        videoLoad(_elm) {
            _elm.addEventListener('load'||'DOMContentLoaded' , this.videoStart(_elm));
        },
        videoStart(_elm) {
            setTimeout(() => {
                _elm.classList.remove('close');
                setTimeout(() => {
                    if(!this.pageOn) return;
                    _elm.play();
                    if(this.beforeElm) this.elmDelete(this.beforeElm);
                }, 500);
            }, 1000);
            this.curentElm = _elm;
            this.curentElm.addEventListener('ended', this.mediaEnd);
        },
        mediaRestart() {
            this.beforeElm = this.curentElm;

            if(this.itemList[this.counter].type === 'html') return this.htmlCreate();
            this.videoCreateOrigin();
        },
        mediaEndCheck() {
            var length = this.itemList.length;
            this.counter++;
            if(length > this.counter) return true;

            if(this.action){
                this.counter = 0
                this.action = false;
                return true;
            }

            this.counter = 0
            this.action = false;
            this.$emit('fullAddModalOpen01', true);
            return false;
        },
        mediaEnd() {
            if(this.keepData) this.keepData = false;
            this.curentElm.removeEventListener('ended', this.mediaEnd);
            if(!this.mediaEndCheck()) return;
            this.beforeElm = this.curentElm;

            if(this.itemList[this.counter].type === 'html') return this.htmlCreate();

            this.videoCreateOrigin();
        },
    },
    created() {

    },
    mounted() {
        // this.mediaInit();
    },
    destroyed() {
        this.keep();

        this.curentElm.removeEventListener('ended', this.mediaEnd);
        document.removeEventListener("pointerup", this.eventCheck);
        this.timer(false);

        this.pageOn = false;
        if(this.curentElm) {
            this.curentElm.muted = true;
            while(this.elmBox.firstChild){
                this.elmBox.removeChild(this.elmBox.firstChild);
            }
            this.curentElm = '';
            this.beforeElm = '';
        }
    }
}
</script>

<style>
    .headAdArea__container {
        width: 100%;
        height: 100%;
        position: relative;
    }
    .headAdArea__list {
        width: 100%;
        height: 100%;
        background-color: #D3D3D3;
        background: url(../../public/img/touchselect-01.png) no-repeat center center / cover;
        overflow: hidden;
        position: relative;
    }
    .topVideo {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 1;
        transition: all 0.1s ease-out;
    }
    .topHtml {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 1;
        transition: all 0.1s ease-out;
    }
    .topVideo.close,
    .topHtml.close {
        opacity: 0.0;
    }
</style>