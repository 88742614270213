import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import annualSchedule from '../views/annualSchedule.vue'
import floorMap from '../views/floorMap.vue'
import goods from '../views/goods.vue'
import publicTransport from '../views/publicTransport.vue'
import info from '../views/info'
import shop from '../views/shop'
import cafe from '../views/cafe'
import library from '../views/library'
import vr from '../views/vr'
import fulladd from '../views/fulladd'
import interactiveMovie from '../views/interactiveMovie'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/public_transport',
    name: 'publicTransport',
    component: publicTransport,
  },
  {
    path: '/floor_map',
    name: 'floorMap',
    component: floorMap,
  },
  {
    path: '/goods',
    name: 'goods',
    component: goods,
  },
  {
    path: '/annual_schedule',
    name: 'annualSchedule',
    component: annualSchedule,
  },
  {
    path: '/info',
    name: 'info',
    component: info
  },
  {
    path: '/shop',
    name: 'shop',
    component: shop
  },
  {
    path: '/cafe',
    name: 'cafe',
    component: cafe
  },
  {
    path: '/library',
    name: 'library',
    component: library
  },
  {
    path: '/vr',
    name: 'vr',
    component: vr
  },
  {
    path: '/fulladd',
    name: 'fulladd',
    component: fulladd
  },
  {
    path: '/interactiveMovie',
    name: 'interactiveMovie',
    component: interactiveMovie
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
