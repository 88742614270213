<template>
    <div class="busTimeTableModal overlay" v-on:touchend.self="busModalClose">
        <div  class="mainContent__list busStopModal">
            <div class="mainContent__list--head modal">
                <span class="busStopModal__head--title" v-html="val.name"></span>
                <div v-on:touchend="busModalClose" v-on:touchstart="addClass($event)" class="modal__close--btn  linkElm">
                    <span class="modal__close--btn--inner modal__close--btn--inner01"></span>
                    <span class="modal__close--btn--inner modal__close--btn--inner02"></span>
                </div>
            </div>
            <div class="busStopModal__content--outer">
                <div class="busStopModal__qp--content">
                    <div class="busStopModal__qp--content--right">
                        <div class="busStopModal__qp--text">
                            現在の運行状況は、<br
                            >QRでご確認ください。
                        </div>
                    </div>
                    <div class="busStopModal__qp--content--left">
                        <img class="busStopModal__qp--img" v-if="val" v-bind:src="val.time_table.qr" alt="">
                    </div>
                </div>
                <div class="busStopModal__content">
                    <div v-if="val.time_table" v-show="!arrayCheck(imgSelect(val.time_table))" class="busStopModal__content--img--box">
                        <img v-bind:src="imgSelect(val.time_table)" class="busStopModal__content--img" alt="">
                    </div>
                    <div v-if="val.time_table" v-show="arrayCheck(imgSelect(val.time_table))" class="busStopModal__content--img--box array">
                        <div>
                            <div v-for="item in imgSelect(val.time_table)" v-bind:key="item.id" class="busStopModal__content--img--box">
                                <img v-bind:src="item" class="busStopModal__content--img" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { linkEvent } from '../mixin02.js'
export default {
    name: "busTimeTableModal",
    data() {
        return{
            modalToggle: false,
            // 曜日判定
            weekCate: "",
            now: "0",
            weekChecker:[
                "holiday",
                "weekday",
                "weekday",
                "weekday",
                "weekday",
                "weekday",
                "saturday",
            ],
        }
    },
    mixins: [linkEvent],
    props: ['val'],
    methods: {
        busModalClose() {
            this.$emit('busModalClose', this.modalToggle);
        },
        weekCheck() {
            this.now = new Date().getDay();
            this.weekCate = this.weekChecker[this.now];
        },
        imgSelect(val) {
            return val[this.weekCate];
        },
        arrayCheck(val) {
            let box = val;
            return Array.isArray(box);
        },
    },
    computed: {
    },
    created() {
        this.weekCheck()
    },
}
</script>

<style>
.busStopModal {
    width: 1000px !important;
    height: auto;
    background-color: #fff;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.busStopModal__content--outer {
    padding: 60px 100px;
}
.busStopModal__content {

}
.busStopModal__content--img--box {
    width: 100%;
    height: auto;
    margin: 0 0 50px 0;
}
.busStopModal__content--img--box:nth-last-child(1) {
    margin-bottom: 0;
}
.busStopModal__content--img {
    width: 100%;
    height: auto;
}

/* QR */
.busStopModal__qp--content {
    display: flex;
    width: 380px;
    margin: 0 auto 30px auto;
    border-radius: 5px;
    background-color: var(--mainColor);
}
.busStopModal__qp--content--right {
    /* width: 304px; */
    width: 260px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.busStopModal__qp--text {
    position: absolute;;
    color: #fff;
    padding: 8px 0px 8px 8px;
    line-height: 1.5;
}
.busStopModal__qp--content--left {
    width: 120px;
    overflow: hidden;
    /* border: solid 3px var(--mainColor); */
    border-radius: 5px;
    box-sizing: border-box;
    padding: 8px;
}
.busStopModal__qp--img {
    display: block;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

</style>