<template>
    <div class="trainTimeTable">
        <div class="trainTimeTable__title">宮崎神宮駅</div>
        <div class="trainTimeTable__timetable--outer">
            <div class="trainTimeTable__timetable">
                <img class="trainTimeTable__timetable--img" src="../../public/img/12train/train__timeTable01.jpg" alt="">
            </div>
            <div class="trainTimeTable__timetable">
                <img class="trainTimeTable__timetable--img" src="../../public/img/12train/train__timeTable02.jpg" alt="">
            </div>
        </div>
        <div class="trainTimeTable__under--box">
            <p class="trainTimeTable__under--text">
                ※実際の運行状況については遅延等の発生により表と異なる場合がございます。
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'trainTimeTable',
    data() {
        return{

        }
    },
    methods: {
        
    },
    created: function() {

    },
}

</script>

<style>
/* 電車時刻表コンポーネント */
.trainTimeTable {

}
.trainTimeTable__title {
    font-size: 4.0rem;
    font-weight: 500;
    color: var(--mainColor);
    text-align: center;
}
.trainTimeTable__timetable--outer {
    margin: 40px 0 0 0;
    display: flex;
    justify-content: space-between;
    gap: 0 40px;
}
.trainTimeTable__timetable {
    width: 47%;
}
.trainTimeTable__timetable--img {
    width: 100%;
}
.trainTimeTable__under--box {
    margin: 40px 0 0 0;
}
.trainTimeTable__under--text {
    line-height: 1.5;
}




</style>