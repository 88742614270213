<template>
    <div class="mainContent__list--components mainContent__list--skd--components" v-on:pointerup="filterToggle">
        <div class="mainContent__list--head">本日のスケジュール</div>
        <div class="mainContent__list--content--outer mainContent__list--content--outer--scroll">
            <div class="mainContent__list--content--outer--scrollBar">
                <div class="mainContent__list--content">
                    <div class="mainContent__list--skd--navBox">
                        <input type="radio" v-model="filterCat" name="todayScheduleCat" value="01exhibition-cat" id="01exhibition-cat" class="exhibition-cat" checked="checked">
                        <label for="01exhibition-cat" v-on:touchstart="addClass($event)" class="mainContent__list--skd--navItem linkElm mainContent__list--skd--navItem--active">
                            特別展
                        </label>
                        <input type="radio" v-model="filterCat" name="todayScheduleCat" value="02exhibition-cat" id="02exhibition-cat" class="exhibition-cat">
                        <label for="02exhibition-cat" v-on:touchstart="addClass($event)" class="mainContent__list--skd--navItem linkElm">
                            コレクション展
                        </label>
                        <input type="radio" v-model="filterCat" name="todayScheduleCat" value="03exhibition-cat" id="03exhibition-cat" class="exhibition-cat">
                        <label for="03exhibition-cat" v-on:touchstart="addClass($event)" class="mainContent__list--skd--navItem linkElm">
                            講座等
                        </label>
                        <input type="radio" v-model="filterCat" name="todayScheduleCat" value="04exhibition-cat" id="04exhibition-cat" class="exhibition-cat">
                        <label for="04exhibition-cat" v-on:touchstart="addClass($event)" class="mainContent__list--skd--navItem linkElm">
                            県民ギャラリー
                        </label>
                    </div>
                    <div class="mainContent__list--skd--content--wrapper">
                        <!-- 繰り返し -->
                        <div v-for="item in scheduleFilter" v-bind:key="item.id" v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="openModal($event, item)" class="mainContent__list--skd--content linkElm">
                            <div class="mainContent__list--skd--flexBox">
                                <div class="mainContent__list--skd--left">
                                    <img v-bind:src="item.thum" v-bind:alt="item.thumAlt" class="mainContent__list--skd--left--img">
                                </div>
                                <div class="mainContent__list--skd--right">
                                    <div v-html="item.title" class="mainContent__list--skd--title"></div>
                                    <div class="mainContent__list--skd--text">
                                        <p v-html="getExcerpt(item.excerpt, 50)"></p>
                                    </div>
                                </div>
                            </div>
                            <div class="mainContent__list--skd--time">
                                <div v-html="item.customField" class="mainContent__list--skd--time--text">
                                </div>
                            </div>
                        </div>
                        <!-- 繰り返しここまで -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { HOST } from '../mixin.js'
import { getPostOption } from '../mixin.js'
import { touchEvent, linkEvent } from '../mixin02.js'

export default {
    name: 'topSchedule',
    data() {
        return {
            todayScheduleList: [],
            filterCat: '01exhibition-cat',
            filterCatToggle: true,
            destroyToggle: false,
            // modal
            modalTogel: false,
            modalItem: '',
            
        }
    },
    mixins: [
        getPostOption,
        touchEvent,
        linkEvent
    ],
    watch: {
        filterCat: function(newval) {
            this.gaEvent('schedule_category_toggle', newval);
        },
    },
    methods: {
        getSchedule() {
            this.$axios
                .get(HOST + '/wp-json/wp/v2/todeayexhibition?_embed')
                .then( response => {
                    for( let item of response.data ) {
                        // console.log(item);
                        this.todayScheduleList.push({
                            id: item.id,
                            title: item.title.rendered,
                            content: item.content.rendered,
                            excerpt: item.excerpt.rendered,
                            thum: item._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url,
                            thumAlt: item._embedded['wp:featuredmedia'][0].alt_text,
                            term: item._embedded['wp:term'][0][0].slug,
                            customField: item.acf.todayexhibitiontime,
                            customFieldPeriod: item.acf.period,
                            customFieldVenue: item.acf.venue,
                            customFieldPrice00: item.acf.price00,
                            customFieldPrice00text: item.acf.price00text,
                            customFieldPrice01: item.acf.price01,
                            customFieldPrice02: item.acf.price02,
                            customFieldPrice03: item.acf.price03,
                            customFieldPrice04: item.acf.price04,
                        })
                    }
                })
        },
        catSlider(thisCat) {
            if(this.filterCatToggle) {
                if(thisCat === '01exhibition-cat') {
                    this.filterCat = '02exhibition-cat';
                }else if(thisCat === '02exhibition-cat') {
                    this.filterCat = '03exhibition-cat';
                }else if(thisCat === '03exhibition-cat') {
                    this.filterCat = '04exhibition-cat';
                }else {
                    this.filterCat = '01exhibition-cat';
                }
            } else {
                this.filterCatToggle = true;
            }
            if(!this.destroyToggle) {
                setTimeout(() => {this.catSlider(this.filterCat);}, 10000);
            }
        },
        filterToggle() {
            this.filterCatToggle = false;
        },
        // modal
        openModal($event, item) {
            if(!this.touchEnd($event)) return;
            this.modalTogel = true;
            this.$emit('scheduleModalOpen', this.modalTogel, item);
            this.gaEvent('schedule_modal_open', item.title);
        },
        gaEvent(cate, label) {
            this.$ga.event(cate, 'click', label);
        },
    },
    computed: {
        scheduleFilter: function() {
            return this.todayScheduleList.filter(
                (item) => !item.term.indexOf(this.filterCat),
            );
        },
    },
    created: function(){
        this.getSchedule();
    },
    beforeDestroy() {
        this.destroyToggle = true;
    }
}
</script>

<style>
/* スケジュール */
.mainContent__list--skd--navBox {
    width: calc(100% - 36px);
    /* background-color: var(--listBgColor); */
    position: relative;
    z-index: 5;
    padding: 12px 0 12px 0;
    display: flex;
    justify-content: center;
    gap: 0 8px;
    position: absolute;
    top: 0;
    left: 18px;
}
.mainContent__list--skd--navItem {
    display: inline-block;
    font-size: 1.3rem;
    font-weight: 500;
    color: #2F2F2F;
    background-color: var(--grayColor);
    padding: 14px 13px 14px;
    border-radius: 10px;
    white-space: nowrap;
}

.exhibition-cat {
  display: none;
}
.exhibition-cat:checked + .mainContent__list--skd--navItem {
    background-color: var(--mainColor);
    color: #fff;
}
.mainContent__list--skd--content--wrapper {
    margin: 60px 0 0 0;
}
.mainContent__list--skd--content {
    width: 100%;
    background-color: var(--listBgColor);
    border-radius: 3px;
    filter: drop-shadow(0 2px 3px rgba(0,0,0,0.1));
    margin: 0 0 22px 0;
    /* padding: 20px 25px 16px; */
    overflow: hidden;
    position: relative;
}
.mainContent__list--skd--content:nth-last-child(1) {
    margin-bottom: 12px;
}
.mainContent__list--skd--content::before {
    content: "";
    display: block;
    width: 100%;
    height: 4px;
    background-color: var(--mainColor);
    position: absolute;
    top: 0;
    left: 0;
}
.mainContent__list--skd--flexBox {
    display: flex;
    align-items: center;
    gap: 0 15px;
    padding: 20px 25px 16px;
}
.mainContent__list--skd--left {
    width: 30%;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
}
.mainContent__list--skd--left::before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 100%;
}
.mainContent__list--skd--left--img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
}
.mainContent__list--skd--right {
    width: 70%;
}
.mainContent__list--skd--title {
    font-size: 2.0rem;
    font-weight: 500;
}
.mainContent__list--skd--text {
    font-size: 1.5rem;
    text-align: justify;
    margin: 12px 0 0 0;
    font-weight: 500;
}
.mainContent__list--skd--text p {
    line-height: 1.5;
}
.mainContent__list--skd--time {
    width: 100%;
    height: 35px;
    background-color: #F7F7F7;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 10px;
}
.mainContent__list--skd--time--text {
    font-size: 2.0rem;
    font-weight: 500;
    color: #7B7B7B;
}
</style>