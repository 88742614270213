<template>
    <div class="views__container cafe">
        <div class="views__container--head">
            ぴあアート
            <a v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="router($event, '/')" class="back__btn linkElm">
                <div class="back__btn--icon">
                <img class="back__btn--icon--img" src="../..//public/icon/svg/home.svg" alt="">
                </div>
                <div class="back__btn--title">TOPへ戻る</div>
            </a>
        </div>
        <div class="views__container--inner">
            <div class="minPage__container">
                <div class="minPage__head--box">
                    <div class="minPage__head--text">
                        明るく開放的な空間で、ゆったりとしたひとときを過ごしませんか。<br
                        >「喫茶室ぴあアート」へぜひお立ち寄りください。
                    </div>
                </div>
                <div class="minPage__block">
                    <div class="menu__box">
                        <div class="menu__left">
                            <div class="menu__title">メニュー</div>
                            <div class="menu__content">
                                <div class="menu__list">
                                    <div class="menu__list--title">日替わりランチ</div>
                                    <div class="menu__list--bar"></div>
                                    <div class="menu__list--price">800</div>
                                </div>
                                <div class="menu__list">
                                    <div class="menu__list--title">ホットサンド</div>
                                    <div class="menu__list--bar"></div>
                                    <div class="menu__list--price">800</div>
                                </div>
                                <div class="menu__list">
                                    <div class="menu__list--title">高菜ピラフ</div>
                                    <div class="menu__list--bar"></div>
                                    <div class="menu__list--price">800</div>
                                </div>
                            </div>
                        </div>
                        <div class="menu__right">
                            <div class="menu__title">ドリンク</div>
                            <div class="menu__content">
                                <div class="menu__list">
                                    <div class="menu__list--title">ホットコーヒー</div>
                                    <div class="menu__list--bar"></div>
                                    <div class="menu__list--price">500</div>
                                </div>
                                <div class="menu__list">
                                    <div class="menu__list--title">アイスコーヒー</div>
                                    <div class="menu__list--bar"></div>
                                    <div class="menu__list--price">500</div>
                                </div>
                                <div class="menu__list">
                                    <div class="menu__list--title">紅茶</div>
                                    <div class="menu__list--bar"></div>
                                    <div class="menu__list--price">500</div>
                                </div>
                                <div class="menu__list">
                                    <div class="menu__list--title">大麦若葉</div>
                                    <div class="menu__list--bar"></div>
                                    <div class="menu__list--price">500</div>
                                </div>
                                <div class="menu__list">
                                    <div class="menu__list--title">アップルジュース</div>
                                    <div class="menu__list--bar"></div>
                                    <div class="menu__list--price">500</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="menuImg__box">
                        <div class="menuImg__box--inner">
                            <img class="menuImg" src="https://stdichigeki.com/signage/wp-content/uploads/2021/08/messageImage_1628140702822.jpg" alt="">
                        </div>
                        <div class="menuImg__box--inner">
                            <img class="menuImg" src="https://stdichigeki.com/signage/wp-content/uploads/2021/08/messageImage_1628140681830.jpg" alt="">
                        </div>
                        <div class="menuImg__box--inner">
                            <img class="menuImg" src="https://stdichigeki.com/signage/wp-content/uploads/2021/08/messageImage_1628140692094.jpg" alt="">
                        </div>
                    </div>
                    <div class="menuUnderContent__box">
                        <div class="menuUnderContent">土・日・祝は限定週替わりのカレーとパスタがあります！</div>
                    </div>
                </div>
                <div class="minPage__block flex">
                    <div class="minPage__block--left">
                        <div class="minPage__block--title">店長より</div>
                    </div>
                    <div class="minPage__block--right">
                        <div class="minPage__block--content">
                            <p class="minPage__block--content--p">
                                宮崎市より指定を受けて福祉サービス事業を行っています。<br
                                >全国でも珍しく、障がい者施設が美術館の中でお店を運営させていただき、メンバー一同嬉しく感じています。メンバーの方々と一緒に手作りのランチやケーキセット、コーヒー等を準備してお待ちしています。
                            </p>
                        </div>
                    </div>
                </div>
                <div class="minPage__block flex">
                    <div class="minPage__block--left">
                        <div class="minPage__block--title">営業時間</div>
                    </div>
                    <div class="minPage__block--right">
                        <div class="minPage__block--content">
                            <div class="minPage__block--content--dl--outer">
                                <dl class="minPage__block--content--dl">
                                    <dt class="minPage__block--content--dt wid140">
                                        営業時間
                                    </dt>
                                    <dd class="minPage__block--content--dd wid140">
                                        11:30～17:00
                                    </dd>
                                </dl>
                                <dl class="minPage__block--content--dl">
                                    <dt class="minPage__block--content--dt wid140">
                                        ランチ(火～金)
                                    </dt>
                                    <dd class="minPage__block--content--dd wid140">
                                        11:30～14:00 (ラストオーダー13:30)
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import router from '../router'
import { touchEvent, linkEvent } from '../mixin02.js'
export default {
    data() {
        return{

        }
    },
    mixins: [touchEvent, linkEvent],
    methods: {
        router($event, path) {
            if(!this.touchEnd($event)) return;
            router.push(path);
        },
    },
}
</script>

<style>
.minPage__head--box {
    width: 100%;
    margin: 0 0 60px 0;
    text-align: center;
}
.minPage__head--text {
    font-size: 2.1rem;
    font-weight: 600;
    line-height: 1.6;
    letter-spacing: 0.04em;
}
.menu__box {
    width: 100%;
    display: flex;
    gap: 0 40px;
    font-weight: 500;
}
.menu__left,
.menu__right {
    width: 50%;
}
.menu__title {
    color: var(--mainColor);
    font-size: 2.1rem;
    font-weight: 600;
    text-align: center;
}
.menu__content {
    margin: 25px 0 0 0;
    width: 100%;
}
.menu__list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 0 20px 0;
}
.menu__list:nth-last-child(1) {
    margin-bottom: 0;
}
.menu__list--title {

}
.menu__list--bar {
    flex-grow: 1;
    box-sizing: border-box;
    padding: 0 10px;
    display: flex;
    align-items: center;
}
.menu__list--bar::before {
    content: "";
    display: block;
    width: 100%;
    height: 0;
    border-top: dotted 2px #707070;
}
.menu__list--price {
}
.menu__list--price::before {
    content: "￥";
}
.menu__list--price::after {
    content: "(税込)";
    font-size: 1.2rem;
    margin: 0 0 0 2pxs;
    position: relative;
    top: -1px;
}
.menuImg__box {
    width: 100%;
    margin: 40px 0 0 0;
    display: flex;
    justify-content: space-between;
}
.menuImg__box--inner {
    width: 31%;
    height: auto;
}
.menuImg {
    width: 100%;
    height: auto;
}
.menuUnderContent__box {
    margin: 40px 0 0 0;
    width: 100%;
    text-align: center;
}
.menuUnderContent {
    font-weight: 600;
    font-size: 1.7rem;
    letter-spacing: 0.05em;
    display: inline-block;
    color: var(--mainColor);
    line-height: 1;
    padding: 17px 20px 15px;
    border-radius: 50px;
}



</style>