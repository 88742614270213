<template>
    <div class="mainContent__list--components mainContent__list--nav--components">
        <div class="mainContent__list--content--outer">
            <div class="mainContent__list--content">
                <div class="mainContent__list--nav--box">
                    <!--  -->
                    <a v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="router($event, '/floor_map')" class="mainContent__list--nav--list linkElm">
                        <div class="mainContent__list--nav--list--inner">
                            <div class="mainContent__list--nav--list--icon">
                                <img class="mainContent__list--nav--list--icon--img"
                                    src="../../public/icon/svg/position.svg" alt="">
                            </div>
                            <div class="mainContent__list--nav--list--title">フロアマップ
                            </div>
                        </div>
                    </a>
                    <div class="mainContent__list--nav--content">
                        <div class="mainContent__list--nav--content--inner">
                            <div class="mainContent__list--nav--dayBox">
                                <div class="mainContent__list--nav--day">{{ date }}</div>
                                <div class="mainContent__list--nav--week">{{ week }}</div>
                            </div>
                            <div class="mainContent__list--nav--time">{{ time }}</div>
                        </div>
                    </div>
                    <a v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="router($event, '/goods')" class="mainContent__list--nav--list linkElm">
                        <div class="mainContent__list--nav--list--inner">
                            <div class="mainContent__list--nav--list--icon">
                                <img class="mainContent__list--nav--list--icon--img"
                                    src="../../public/icon/svg/goods.svg" alt="">
                            </div>
                            <div class="mainContent__list--nav--list--title">グッズ一覧</div>
                        </div>
                    </a>
                    <a v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="router($event, '/annual_schedule')" class="mainContent__list--nav--list linkElm">
                        <div class="mainContent__list--nav--list--inner">
                            <div class="mainContent__list--nav--list--icon">
                                <img class="mainContent__list--nav--list--icon--img"
                                    src="../../public/icon/svg/calendar.svg" alt="">
                            </div>
                            <div class="mainContent__list--nav--list--title">年間スケジュール
                            </div>
                        </div>
                    </a>
                    <a v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="router($event, '/public_transport')" class="mainContent__list--nav--list linkElm">
                        <div class="mainContent__list--nav--list--inner">
                            <div class="mainContent__list--nav--list--icon">
                                <img class="mainContent__list--nav--list--icon--img"
                                    src="../../public/icon/svg/bus.svg" alt="">
                            </div>
                            <div class="mainContent__list--nav--list--title">公共交通機関
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import router from '../router'
import { touchEvent, linkEvent } from '../mixin02.js'
export default {
    name: 'topNav',
    data() {
        return{
            date: '',
            weekDate: ['(日)','(月)','(火)','(水)','(木)','(金)','(土)',],
            week: '',
            time: '',
        }
    },
    mixins: [touchEvent, linkEvent],
    mounted: function()  {
        //10秒ごとに動かす(変更可能) 
        setInterval(this.updateTime, 10000);
    }, 
    methods:{
        router($event, path) {
            if(!this.touchEnd($event)) return;
            router.push(path);
            this.gaEvent('nav_click', path);
        },
        gaEvent(cate, label) {
            this.$ga.event(cate, 'click', label);
        },
        updateTime: function() { 
            //現在の日付・時刻を取得 
            let current_date = new Date();
            this.date = this.zeroPadding(current_date.getFullYear(), 4) + '.' + this.zeroPadding((current_date.getMonth() + 1), 2) + '.' + this.zeroPadding(current_date.getDate(),2);
            this.time = this.zeroPadding(current_date.getHours(), 2) + ':' + this.zeroPadding(current_date.getMinutes(), 2);
            this.week = this.weekDate[current_date.getDay()];
        },
        zeroPadding: function(num, len) {
            let zero = '';
            //0の文字列を作成
            for(var i = 0; i < len; i++) {
                zero += '0';
            }
            // zeroの文字列と、数字を結合し、後ろ２文字を返す
            return (zero + num).slice(-len);
        },
    },
    created() {
        this.updateTime();
    }
}
</script>
<style>
/* ナビゲーション */
.mainContent__list--nav .mainContent__list--content--outer {
    height: 100%;
}
.mainContent__list--nav--box {
    display: flex;
    flex-wrap: wrap;
    gap: 18px 18px;
}
.mainContent__list--nav--list {
    width: calc(33.3333% - 12px);
    background-color: var(--mainColor);
    position: relative;
    border-radius: 20px;
}
.mainContent__list--nav--list::before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 100%;
}
.mainContent__list--nav .mainContent__list--content--outer {
    padding-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.mainContent__list--nav--list--inner {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
.mainContent__list--nav--list--icon {
    width: 50%;
    position: relative;
}
.mainContent__list--nav--list--icon::before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 100%;
}
.mainContent__list--nav--list--icon--img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
}
.mainContent__list--nav--list--title {
    color: var(--headerTextColor);
    margin: 15px 0 10px 0;
    font-size: 12px;
    font-weight: 500;
}
.mainContent__list--nav--content {
    width: calc(66.6666% - 6px);
    background-color: #F7F7F7;
    border-radius: 20px;
}
.mainContent__list--nav--content--inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.mainContent__list--nav--dayBox {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
}
.mainContent__list--nav--day {
    font-size: 3.8rem;
}
.mainContent__list--nav--week {
    font-size: 2.6rem;
}
.mainContent__list--nav--time {
    font-size: 5.2rem;
    font-weight: 600;
    margin: 15px 0 0 0;
}
</style>