<template>
    <div class="mainContent__list--components mainContent__list--guest--components">
        <div class="mainContent__list--content--outer guest">
            <div class="mainContent__list--content guest">
                <div class="topGuest__flex--box">
                    <div class="topGuest__content--left">
                        <div class="topGuest__content--left--head">
                            来場<br>者数
                        </div>
                        <div class="topGuest__content--left-flip">
                            <div class="tick" ref="tick" data-did-init="setupFlip">
                                <div data-repeat="true" aria-hidden="true">
                                    <span class="flip__item" data-view="flip"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="topGuest__content--right linkElm" v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="guestModalOpen($event)">
                        <div class="topGuest__content--text--outer">
                            <span class="topGuest__content--text01">プレゼント</span>
                            <span class="topGuest__content--text02">企画実施中!!</span>
                        </div>
                        <span class="topGuest__content--text--min">詳しくはコチラ</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { HOST } from '../mixin.js'
import Tick from '../@pqina/flip'
import '../@pqina/flip/dist/flip.min'
import { touchEvent, linkEvent } from '../mixin02.js'
export default {
    name: 'topGuest',
    data(){
        return {
            guestNum: '00000',
            counter: 0,
            guestModalToggle: false,
            deleteMethods: false,
        }
    },
    mixins: [touchEvent, linkEvent],
    watch: {
    },
    methods: {
        getGuest() {
            this.$axios
                .get(HOST + '/wp-json/wp/v2/pages/73?_embed')
                .then( response => {
                    this.guestNum = ( '00000' + response.data.acf.guestnum ).slice( -5 );
                })
                this.setupFlip();
        },
        setupFlip() {
            setTimeout(() => {
                this._tick.value = this.guestNum;
            }, 1000);
        },
        guestModalOpen($event) {
            if(!this.touchEnd($event)) return;
            this.guestModalToggle = true;
            this.$emit('guestModalOpen', this.guestModalToggle);
            this.$ga.event('guest_modal_open', 'click', 'guestModalOpen_label');
        },
    },
    created() {
        
    },
    mounted() {
        this._tick = Tick.DOM.create(this.$refs.tick, {
            value: this.guestNum,
        });
        this.getGuest();
    },
    beforeDestroy() {
        this.deleteMethods = true;
    }
}
</script>

<style>
/* 来場者数 */
.mainContent__list--content--outer.guest {
    padding: 0 0 0 0;
    width: 100%;
    height: 100%;
}
.mainContent__list--content.guest {
    width: 100%;
    height: 100%;
}
.topGuest__flex--box {
    width: 100%;
    height: 100%;
    display: flex;
}
.topGuest__content--left {
    width: calc(100% - 156px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 10px;
}
.topGuest__content--left--head {
    font-size: 2.6rem;
    font-weight: 600;
    color: var(--mainColor);
    border-top: solid 2px var(--mainColor);
    border-bottom: solid 2px var(--mainColor);
    padding: 3px 0 0;
    letter-spacing: 0.1rem;
    line-height: 1.15;
}
.topGuest__content--left-flip {
    display: flex;
    align-items: flex-end;
}
.topGuest__content--left-flip::after {
    content: "人";
    font-size: 2.6rem;
    font-weight: 600;
    color: var(--mainColor);
}
/* flip */
.tick {

}
.flip__item {
    font-size: 5.0rem;
    font-weight: 600;
}
.tick-flip {
    min-width: 40px;
    margin: 0 .06em 0 .06em;
    border-radius: 8px;
    letter-spacing: 0em;
    text-indent: 0em;
}
.tick-flip-card:nth-last-child(1) {
    transition: all 0.1s linear ease-in;
}
.tick-flip-card:nth-last-child(2) {
    transition: all 0.2s linear ease-in;
}
.tick-flip-card:nth-last-child(3) {
    transition: all 0.3s linear ease-in;
}
.tick-flip-card:nth-last-child(4) {
    transition: all 0.4s linear ease-in;
}
.tick-flip-card:nth-last-child(5) {
    transition: all 0.5s linear ease-in;
}
.tick-flip-card:nth-last-child(6) {
    transition: all 0.6s linear ease-in;
}

.tick-flip:nth-last-child(4) {
    margin: 0 10px 0 0;
    position: relative;
}
.tick-flip:nth-last-child(4)::after {
    content: ",";
    position: absolute;
    bottom: -2px;
    right: -11px;
    color: var(--mainColor);
}
.tick-flip-panel {
    color: var(--headerTextColor);
    background-color: var(--mainColor);
}
.tick-flip-shadow {
    /* box-shadow: 0 0 1em #000; */
}

.topGuest__content--right {
    width: 156px;
    height: 100%;
    background-color: #ffff00;
    color: var(--mainColor);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.topGuest__content--text--outer {
    font-weight: 600;
}
.topGuest__content--text01 {
    font-size: 2.3rem;
    display: block;
}
.topGuest__content--text02 {
    font-size: 2.0rem;
}
.topGuest__content--text--min {
    font-size: 1.2rem;
    text-decoration: underline;
    font-weight: 600;
    display: inline-block;
    margin: 4px 0 0 0;
}



</style>