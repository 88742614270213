<template>
    <div class="views__container cafe">
        <div class="views__container--head" style="display:none;">
            VR
            <a v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="router($event, '/')" class="back__btn linkElm">
                <div class="back__btn--icon">
                <img class="back__btn--icon--img" src="../..//public/icon/svg/home.svg" alt="">
                </div>
                <div class="back__btn--title">TOPへ戻る</div>
            </a>
        </div>
        <div class="views__container--inner" style="height:100%">
            <div class="minPage__container vr">
                <div class="minPage__text">モデルハウスVR</div>
                <div class="minPage__vr">
                    <iframe src="https://r35945623.theta360.biz/t/a78f6e2c-87c3-11eb-bf7d-0a58c1b86054-1" frameborder="0"></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import router from '../router'
import { touchEvent, linkEvent } from '../mixin02.js'
export default {
    data() {
        return {

        }
    },
    mixins: [touchEvent, linkEvent],
    methods: {
        router($event, path) {
            if(!this.touchEnd($event)) return;
            router.push(path);
        },
    },
}
</script>

<style>
.minPage__container.vr {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;
    padding: 0;
}
.minPage__text {
    display: none;
    height: 100px;
    text-align: center;
    font-weight: 600;
    font-size: 5.0rem;
    color: var(--mainColor);
}
.minPage__vr {
    width: 100%;
    /* height: calc(100% - 130px); */
    height: calc(100% + 50px);
    position: relative;
}
.minPage__vr iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


</style>

