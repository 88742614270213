<template>
    <div v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="gameContentModalOpen($event)" class="mainContent__list--components mainContent__list--gameContent--components linkElm"> 
        <div class="mainContent__list--gameContent--contaienr">
            <img class="mainContent__list--gameContent--img" src="https://stdichigeki.com/signage/wp-content/uploads/2021/09/10クロスワードバナー-min.jpg" alt="">
        </div>
    </div>
</template>
<script>
import { touchEvent, linkEvent } from '../mixin02.js'
export default {
    name: 'topGameContent',
    data() {
        return{
            gameContentModalToggle: false,
        }
    },
    mixins: [
        touchEvent,
        linkEvent,
    ],
    methods: {
        gameContentModalOpen($event) {
            if(!this.touchEnd($event)) return;
            this.gameContentModalToggle = true;
            this.$emit('gameContentModalOpen', this.gameContentModalToggle);
            this.gaEvent('gameContent_modal_open', 'gameContentModalOpen_label');
        },
        gaEvent(cate, label) {
            this.$ga.event(cate, 'click', label);
        },
    }
}
</script>
<style>
.mainContent__list--gameContent--components {
    width: 100%;
    height: 100%;
}
.mainContent__list--gameContent--img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
</style>