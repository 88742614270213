<script></script>
<template>
    <div class="mainContent__list--components mainContent__list--ic--components">
        <div class="mainContent__list--head">新型コロナウイルス感染予防について</div>
        <div class="mainContent__list--content--outer">
            <div class="mainContent__list--content ic__content">
                <div class="mainContent__list--ic--container mainContent__list--ic--container--swiper">
                    <div class="mainContent__list--ic--wrapper swiper-wrapper">
                        <div class="mainContent__list--ic--list swiper-slide">
                            <div class="mainContent__list--ic--left">
                                <img class="mainContent__list--ic--left--img" src="../../public/img/09-2.png" alt="">
                            </div>
                            <div class="mainContent__list--ic--right">
                                <div class="mainContent__list--ic--title">
                                    マスク着用
                                </div>
                                <div class="mainContent__list--ic--text">
                                    <p>
                                        館内では常時マスクの着用が必要となります。ただし幼児及び何らかの原因で着けられない方は除きます。ご協力いただけない場合はご入場いただけませんのであらかじめご了承ください。
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="mainContent__list--ic--list swiper-slide">
                            <div class="mainContent__list--ic--left">
                                <img class="mainContent__list--ic--left--img" src="../../public/img/03-1.png" alt="">
                            </div>
                            <div class="mainContent__list--ic--right">
                                <div class="mainContent__list--ic--title">
                                    ソーシャルディスタンス
                                </div>
                                <div class="mainContent__list--ic--text">
                                    <p>
                                        館内では前後1ｍを目安に十分な間隔を保っていただきますようお願い致します。待機列が発生した場合にはソーシャルディスタンスを確保したうえで整列へのご協力をお願い致します。
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="mainContent__list--ic--list swiper-slide">
                            <div class="mainContent__list--ic--left">
                                <img class="mainContent__list--ic--left--img" src="../../public/img/02-1.png" alt="">
                            </div>
                            <div class="mainContent__list--ic--right">
                                <div class="mainContent__list--ic--title">
                                    除菌・消毒
                                </div>
                                <div class="mainContent__list--ic--text">
                                    <p>
                                        ご来館時の手指消毒にご協力をお願い致します。また、館内の各所に消毒用アルコールを設置いたします。こまめな手洗い・手指消毒にご協力ください。
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="mainContent__list--ic--list swiper-slide">
                            <div class="mainContent__list--ic--left">
                                <img class="mainContent__list--ic--left--img" src="../../public/img/01-1.png" alt="">
                            </div>
                            <div class="mainContent__list--ic--right">
                                <div class="mainContent__list--ic--title">
                                    検温実施
                                </div>
                                <div class="mainContent__list--ic--text">
                                    <p>
                                        ご来館時の際に検温を行います。係員の指示に従っていただきますようお願い致します。体調の悪い方、発熱等の症状がある方につきましては、ご来館をお控えください。
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mainContent__list--ic--swiper--btnBox">
                        <div class="swiper-button-prev mainContent__list--ic--swiper--prev"></div>
                        <div class="swiper-button-next mainContent__list--ic--swiper--next"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'topInfectionControl',
    data() {
        return{

        }
    },
    methods: {
        gaEvent() {

        },
    },
    created() {
    }
}
</script>
<style>
/* 感染対策 */
.mainContent__list--ic--components {
    width: 510px;
}
.mainContent__list--ic--container {
    width: 90%;
    height: 100%;
    margin: 0 auto;
    padding-bottom: 12px;
    overflow: hidden;
}
.mainContent__list--ic--wrapper {
    width: 100%;
    height: 100%;
}
.mainContent__list--ic--list {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 0 12px;
}
.mainContent__list--ic--left {
    width: 25%;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
}
.mainContent__list--ic--left::before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 100%;
}
.mainContent__list--ic--left--img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
}
.mainContent__list--ic--right {
    width: 75%;
}
.mainContent__list--ic--title {
    font-size: 1.8rem;
    font-weight: 500;
    padding: 3px 0 0 0;
}
.mainContent__list--ic--text {
    font-size: 1.4rem;
    text-align: justify;
    margin: 10px 0 0 0;
    font-weight: 500;
}
.mainContent__list--ic--text p {
    line-height: 1.5;
}
.mainContent__list--content.ic__content {
    position: relative;
}
.mainContent__list--ic--swiper--btnBox {
    width: 108%;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 5;
}
.mainContent__list--ic--swiper--prev,
.mainContent__list--ic--swiper--next {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 20px;
    height: 40px;
}
.mainContent__list--ic--swiper--prev::after,
.mainContent__list--ic--swiper--next::after {
    content: none;
}
.mainContent__list--ic--swiper--prev {
    background-image: url(../../public/icon/svg/arrow-prevGray.svg);
}
.mainContent__list--ic--swiper--next {
    background-image: url(../../public/icon/svg/arrow-nextGray.svg);
}
</style>