<template>
    <div v-on:touchend.self="closeModal" class="overlay">
        <div class="mainContent__list topGoodsModal">
            <div class="mainContent__list--head modal">
                グッズ紹介
                <div v-on:touchend="closeModal" class="modal__close--btn linkElm" v-on:touchstart="addClass($event)">
                    <span class="modal__close--btn--inner modal__close--btn--inner01"></span>
                    <span class="modal__close--btn--inner modal__close--btn--inner02"></span>
                </div>
            </div>
            <div class="mainContent__list--content--outer--model">
                <div class="topGoods__content">
                    <div class="topGoods__content--left">
                        <zoom03 ref="zoomComp" v-bind:toggle="toggle" v-bind:val="imgTag"></zoom03>
                    </div>
                    <div class="topGoods__content--right">
                        <div class="topGoods__content--right--inner">
                            <div v-html="val.title" class="topGoods__content--title modal"></div>
                            <div v-html="val.content" class="topGoods__content--text modal"></div>
                            <div class="topGoods__content--detail--inner goodsPrice">
                                <div v-html="val.customField" class="topGoods__content--detail--text goodsPrice"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import zoom03 from './zoom03'
import { linkEvent } from '../mixin02.js'
export default {
    name: 'topGoodsModal',
    data() {
        return {
            ModalToggle: false,

            imgTag: '',
            imgSrc: '',
        }
    },
    mixins: [linkEvent],
    components: {
        zoom03,
    },
    props: ['val', 'toggle'],
    methods: {
        closeModal() {
            this.$refs.zoomComp.zoomOff();
            this.$emit('GoodsModalClose', this.ModalToggle);
        },
    },
    created() {
    },
    mounted() {
    },
    beforeUpdate() {
        this.imgSrc = this.val.thum;
        this.imgTag = '<img src="' + this.imgSrc + '" class="topGoods__content--left--img">';
        // this.$refs.zoomComp.zoomOn();
    },
    beforeDestroy() {
    }
}
</script>
<style>
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 1080px;
    min-width: 100%;
    height: 1920px;
    min-height: 100%;
    background-color: rgba(0,0,0,0.3);
}
.topGoodsModal {
    width: 90% !important;
    height: auto;
    max-width: 900px;
    background-color: #fff;
    border-radius: 5px;
    position: absolute;
    top: 625px;
    left: 50%;
    transform: translate(-50%,0%);
}
.mainContent__list--content--modal {

}
.mainContent__list--content--outer--model {
    padding: 30px;
}
.mainContent__list--head.modal {
    position: relative;
}
.modal__close--btn {
    position: absolute !important;
    width: 40px;
    height: 40px;
    background-color: var(--headerTextColor);
    border-radius: 50%;
    top: 50%;
    right: 8px;
    transform: translate(0,-50%);
}
.modal__close--btn--inner {
    display: block;
    width: 25px;
    height: 3px;
    border-radius: 3px;
    background-color: var(--mainColor);
    position: absolute;
    top: 50%;
    left: 50%;
}
.modal__close--btn--inner01 {
    transform: translate(-50%,-50%) rotate(-45deg);
}
.modal__close--btn--inner02 {
transform: translate(-50%,-50%) rotate(45deg);
}
.topGoods__content {
    display: flex;
    align-items: flex-start;
    gap: 0 30px;
    flex-direction: column;
    gap: 30px 0;
}
.topGoods__content--left {
    width: 40%;
    width: 80%;
    margin: 0 auto;
    border: solid 1px #eee;
    border-radius: 5px;
    position: relative;
}
.topGoods__content--left::before {
    content: "";
    display: grid;
    width: 100%;
    padding-top: 100%;
}
.topGoods__content--left > * {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.topGoods__content--left--img {
    width: 100%;
    height: 100%;
    object-fit: cover;

}
.topGoods__content--right {
    width: 80%;
    margin: 0 auto;
}
.topGoods__content--right--inner {

}
.topGoods__content--title.modal {
    font-size: 2.9rem;
    font-weight: 500;
}
.topGoods__content--text.modal {
    font-size: 1.5rem;
    text-align: justify;
    margin: 20px 0 0 0;
    font-weight: 500;
}
.topGoods__content--text.modal p {
    line-height: 1.5;
    margin: 0 0 0.8em;
}
.topGoods__content--detail--inner.goodsPrice {
    margin: 40px 0 0 0;
    padding: 15px 0;
    border-top: solid 1px #DFDFDF;
    border-bottom: solid 1px #DFDFDF;
    text-align: right;
}
.topGoods__content--detail--text.goodsPrice::before {
    content: "￥";
}
.topGoods__content--detail--text.goodsPrice::after {
    content: "(税込)";
    font-size: 1.3rem;
    margin: 0 0 0 2px;
}
</style>