<template>
    <div class="mainContent__list--components mainContent__list--products--components">
        <div class="mainContent__list--head">グッズ紹介</div>
        <div class="mainContent__list--content--outer products">
            <div class="mainContent__list--content">
                <swiper :options="swiperOptions" ref="mySwiper" class="mainContent__list--products--container">
                    <!-- 繰り返し -->
                    <swiper-slide v-for="item in goodsList" v-bind:key="item.id" class="mainContent__list--products--list">
                        <div v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="openModal($event, item)" class="mainContent__list--products--list--inner linkElm">
                            <div class="mainContent__list--products--imgBox">
                                <img v-bind:src="item.thum" v-bind:alt="item.thumAlt" class="mainContent__list--products--img">
                            </div>
                            <div v-html="item.title" class="mainContent__list--products--title"></div>
                            <div v-html="item.customField" class="mainContent__list--products--price"></div>
                        </div>
                    </swiper-slide>
                    <!-- 繰り返しここまで -->
                    <div class="swiper-button-prev" slot="button-prev"></div>
                    <div class="swiper-button-next" slot="button-next"></div>
                </swiper>
            </div>
        </div>
    </div>
</template>
<script>
import { HOST } from '../mixin.js'
import { touchEvent, linkEvent } from '../mixin02.js'
export default {
    name: 'topGoodsList',
    data() {
        return {
            goodsList: [],

            noOpen: false,
            // modal
            modalTogel: false,
            modalItem: '',
            swiperOptions: {
                longSwipesRatio: 0.2,
                speed: 800,
                slidesPerColumnFill: 'row',
                slidesPerColumn: 2,
                slidesPerView: 3,
                slidesPerGroup: 3,
                spaceBetween: 0,
                initialSlide: 0,
                // loop: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                followFinger : false,
                videoRestartToggle: false,
            },
        }
    },
    mixins: [touchEvent, linkEvent],
    computed: {
        mySwiper() {
            return this.$refs.mySwiper.$swiper
        }
    },
    methods: {
        getGoods() {
            this.$axios
                .get(HOST + '/wp-json/wp/v2/goods-list?per_page=12&_embed')
                .then( response => {
                    for( let item of response.data ) {
                        this.goodsList.push({
                            id: item.id,
                            title: item.title.rendered,
                            content: item.content.rendered,
                            thum: item._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url,
                            thumAlt: item._embedded['wp:featuredmedia'][0].alt_text,
                            customField: item.acf.goodsprice,
                        })
                    }
                })
        },
        // modal
        openModal($event, item) {
            if(!this.touchEnd($event)) return;
            this.modalTogel = true;
            this.$emit('goodsModalOpen', this.modalTogel, item);
            this.gaEvent('goods_modal_open', item.title);
        },
        gaEvent(cate, label) {
            this.$ga.event(cate, 'click', label);
        },
    },
    created() {
        this.getGoods()
    },
    mounted() {
        // console.log(this.mySwiper);
    }
}
</script>
<style scoped>
    /* グッズ */
    .mainContent__list--content--outer.products {
        padding: 12px 10px 0 10px;
        max-width: 510px;
    }
    .mainContent__list--products--container {
        padding: 10px 0 0px 0;
    }
    .mainContent__list--products--list {
        height: 207px;
        line-height: 207px;
        padding: 0 14px 20px 14px;
    }
    .mainContent__list--products--list--inner {
        width: 100%;
        height: 100%;
    }
    .mainContent__list--products--imgBox {
        width: 100%;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        border: solid 1px #f7f7f7;
    }
    .mainContent__list--products--imgBox::before {
        content: "";
        display: block;
        widows: 100%;
        padding-top: 100%;
    }
    .mainContent__list--products--img {
        width: 100%;
        height: 100%;
        /* object-fit: contain; */
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
    }
    .mainContent__list--products--title {
        margin: 12px 0 0 0;
        font-size: 1.6rem;
        font-weight: 500;
    }
    .mainContent__list--products--price {
        margin: 6px 0 0 0;
        font-size: 1.3rem;
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 0 2px;
    }
    .mainContent__list--products--price::before {
        content: "¥";
    }
    .mainContent__list--products--price::after {
        content: "(税込)";
        font-size: 1.0rem;
    }
    .swiper-button-prev,
    .swiper-button-next {
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 25px;
        height: 50px;
    }
    .swiper-button-prev::after,
    .swiper-button-next::after {
        content: none;
    }
    .swiper-button-prev {
        left: 0;
        background-image: url(../../public/icon/svg/arrow-prevGray.svg);
    }
    .swiper-button-next {
        right: 0;
        background-image: url(../../public/icon/svg/arrow-nextGray.svg);
    }


    .mainContent__list--products--list:nth-child(6n-5) {
        padding: 0 0 0 36px;
    }
    .mainContent__list--products--list:nth-child(6n-4) {
        padding: 0 18px 0;
    }
    .mainContent__list--products--list:nth-child(6n-3) {
        padding: 0 36px 0 0;
    }
    .mainContent__list--products--list:nth-child(6n-2) {
        padding: 0 0 0 36px;
    }
    .mainContent__list--products--list:nth-child(6n-1) {
        padding: 0 18px 0;
    }
    .mainContent__list--products--list:nth-child(6n) {
        padding: 0 36px 0 0;
    }
</style>