<template>
  <div class="doods views__container">
    <div class="views__container--head">
      インタラクティブ動画
      <a v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="router($event, '/')" class="back__btn linkElm">
        <div class="back__btn--icon">
          <img class="back__btn--icon--img" src="../..//public/icon/svg/home.svg" alt="">
        </div>
        <div class="back__btn--title">TOPへ戻る</div>
      </a>
    </div>
    <div class="views__container--inner interactiveMovie">
      <div class="views__container--inner--bar interactiveMovie">
        <div class="interactiveMovie__container">
          <!-- 繰り返し -->
          <div v-for="item in interactiveMovieList" v-bind:key="item.name" v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="openModal($event, item)" class="interactiveMovie__Content--list interactiveMovie__page linkElm">
              <div class="interactiveMovie__Content--imgBox">
                  <img v-bind:src="item.thumSrc" class="interactiveMovie__Content--img">
              </div>
              <div v-html="item.name" class="interactiveMovie__Content--title"></div>
          </div>
          <!-- 繰り返しここまで -->
        </div>
      </div>
    </div>
    <!-- modal -->
    <interactiveMovieSample ref="interactiveMovieSample" v-on:interactiveMovieClose="interactiveMovieClose" v-bind:val="interactiveMovieData" v-show="interactiveMovieToggle" ></interactiveMovieSample>
    <!-- modal end -->
  </div>
</template>

<script>
import router from '../router'
import interactiveMovieSample from '../components/interactiveMovieSample'
import { touchEvent, linkEvent } from '../mixin02.js'
export default {
    name: 'topGoodsList',
    data() {
        return {
            interactiveMovieToggle: false,
            interactiveMovieData: "",
            // ========================
            interactiveMovieList:[
              {
                name: "ポップアップ機能",
                type: "html",
                src: "https://example.mil.movie/E9wzYm/",
                thumSrc: "./img/16interactiveMovie/01sample.png",
                qrSrc: "./img/16interactiveMovie/01qr.png",
              },
              {
                name: "ストーリー分岐機能",
                type: "html",
                src: "https://example.mil.movie/tiDSGw/",
                thumSrc: "./img/16interactiveMovie/02sample.png",
                qrSrc: "./img/16interactiveMovie/02qr.png",
              },
              {
                name: "スイッチング機能",
                type: "html",
                src: "https://example.mil.movie/ZARL29/",
                thumSrc: "./img/16interactiveMovie/03sample.png",
                qrSrc: "./img/16interactiveMovie/03qr.png",
              },
              {
                name: "PR用360°動画",
                type: "html",
                src: "https://example.mil.movie/QPhJcd/",
                thumSrc: "./img/16interactiveMovie/04sample.png",
                qrSrc: "./img/16interactiveMovie/04qr.png",
              },
              {
                name: "リクルート",
                type: "html",
                src: "https://example.mil.movie/7PWhoW/",
                thumSrc: "./img/16interactiveMovie/05sample.jpeg",
                qrSrc: "./img/16interactiveMovie/05qr.png",
              },
            ]
            // ========================
        }
    },
    mixins: [touchEvent, linkEvent],
    components: {
      interactiveMovieSample,
    },
    methods: {
        router($event, path) {
          if(!this.touchEnd($event)) return;
          router.push(path);
        },
        interactiveMovieClose(toggle) {
            this.interactiveMovieToggle = toggle;
            this.interactiveMovieData = "";
        },
        // modal
        openModal($event, item) {
          if(!this.touchEnd($event)) return;
          this.interactiveMovieToggle = true;
          this.interactiveMovieData = item;
          this.$refs.interactiveMovieSample.fullAddToggle();
        },
    },
    created: function() {
    }
}
</script>

<style>
/* グッズ */
.interactiveMovie__Content--list {
    width: calc(33.3333% - 16px);
}
.interactiveMovie__Content--imgBox {
    width: 100%;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    border: solid 1px #f7f7f7;
}
.interactiveMovie__Content--imgBox::before {
    content: "";
    display: block;
    widows: 100%;
    padding-top: 56.3%;
}
.interactiveMovie__Content--img {
    width: 100%;
    height: 100%;
    /* object-fit: contain; */
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}
.interactiveMovie__Content--title {
    margin: 12px 0 0 0;
    font-size: 1.6rem;
    font-weight: 500;
}

.views__container--inner.interactiveMovie {
  padding: 0 10px 30px 0;
}
.views__container--inner--bar.interactiveMovie {
  width: 100%;
  height: 100%;
  overflow: overlay;
}
.views__container--inner--bar.interactiveMovie::-webkit-scrollbar {
    width: 12px;
}
.views__container--inner--bar.interactiveMovie::-webkit-scrollbar-track {
    margin: 3px 0;
}
.views__container--inner--bar.interactiveMovie::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #D3D3D3;
}
.interactiveMovie__container {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  padding: 40px 0 0 0;
}
.mainContent__list--products--list.goods__page {
  width: calc(25% - 18px);
}
</style>