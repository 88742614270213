<template>
  <div id="app" ref="app" v-on:touchstart="clickOn">
    <router-view ref="routerView" v-on:keepDataExport="keepDataExport"></router-view>
    <section class="footerNav__section">
        <div class="footerNav">
            <div class="footerNav__container">
                <div class="footerNav__content">
                    <div class="footerNav__content--inner">
                        <a v-on:touchstart="touchOn" v-on:touchend="router($event, '/info')" class="footerNav__list">
                            <div class="footerNav__list--icon--outer linkElm" v-on:touchstart="addClass($event)">
                                <div class="footerNav__list--icon--flex">
                                    <div class="footerNav__list--icon">
                                        <img class="footerNav__list--icon--img" src="../public/icon/svg/info.svg" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="footerNav__list--title linkElm" v-on:touchstart="addClass($event)">ご利用案内</div>
                        </a>
                        <a v-on:touchstart="touchOn" v-on:touchend="router($event, '/shop')" class="footerNav__list">
                            <div class="footerNav__list--icon--outer linkElm" v-on:touchstart="addClass($event)">
                                <div class="footerNav__list--icon--flex">
                                    <div class="footerNav__list--icon">
                                        <img class="footerNav__list--icon--img" src="../public/icon/svg/shop.svg" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="footerNav__list--title linkElm" v-on:touchstart="addClass($event)">ミュージアムショップ</div>
                        </a>
                        <a v-on:touchstart="touchOn" v-on:touchend="router($event, '/cafe')" class="footerNav__list">
                            <div class="footerNav__list--icon--outer linkElm" v-on:touchstart="addClass($event)">
                                <div class="footerNav__list--icon--flex">
                                    <div class="footerNav__list--icon">
                                        <img class="footerNav__list--icon--img" src="../public/icon/svg/cafe.svg" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="footerNav__list--title linkElm" v-on:touchstart="addClass($event)">ぴあアート</div>
                        </a>
                        <a v-on:touchstart="touchOn" v-on:touchend="router($event, '/library')" class="footerNav__list">
                            <div class="footerNav__list--icon--outer linkElm" v-on:touchstart="addClass($event)">
                                <div class="footerNav__list--icon--flex">
                                    <div class="footerNav__list--icon">
                                        <img class="footerNav__list--icon--img" src="../public/icon/svg/book.svg" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="footerNav__list--title linkElm" v-on:touchstart="addClass($event)">美術図書室</div>
                        </a>
                        <a v-on:touchstart="touchOn" v-on:touchend="router($event, '/vr')" class="footerNav__list">
                            <div class="footerNav__list--icon--outer linkElm" v-on:touchstart="addClass($event)">
                                <div class="footerNav__list--icon--flex">
                                    <div class="footerNav__list--icon">
                                        <img class="footerNav__list--icon--img" src="../public/icon/svg/vr360.svg" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="footerNav__list--title linkElm" v-on:touchstart="addClass($event)">VR</div>
                        </a>
                        <a v-on:touchstart="touchOn" v-on:touchend="router($event, '/fulladd')" class="footerNav__list">
                            <div class="footerNav__list--icon--outer linkElm" v-on:touchstart="addClass($event)">
                                <div class="footerNav__list--icon--flex">
                                    <div class="footerNav__list--icon">
                                        <img class="footerNav__list--icon--img" src="../public/icon/svg/ad.svg" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="footerNav__list--title linkElm" v-on:touchstart="addClass($event)">縦型表示</div>
                        </a>
                        <a v-on:touchstart="touchOn" v-on:touchend="router($event, '/interactiveMovie')" class="footerNav__list">
                            <div class="footerNav__list--icon--outer linkElm" v-on:touchstart="addClass($event)">
                                <div class="footerNav__list--icon--flex">
                                    <div class="footerNav__list--icon">
                                        <img class="footerNav__list--icon--img" src="../public/icon/svg/interactiveMovie.svg" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="footerNav__list--title linkElm" v-on:touchstart="addClass($event)">インタラクティブ動画</div>
                        </a>
                    </div>
                </div>
                <a v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="router($event, '/')" class="footerNav__home--btn linkElm">
                    <div class="footerNav__home--btn--icon">
                        <img class="footerNav__home--btn--icon--img" src="../public/icon/svg/home.svg" alt="">
                    </div>
                    <div class="footerNav__home--btn--title">HOME</div>
                </a>
            </div>
        </div>
    </section>
  </div>
</template>

<script>
import router from './router'
import { touchEvent, linkEvent } from './mixin02.js'
export default {
    name: 'app',
    data(){
        return{
            timer: '0',
            actionToggle: false,
            Path: this.$route.path,
            clickAction: false,
            backHomeToggle: false,

            modalClose: false,

            media: "",
        }
    },
    mixins: [touchEvent, linkEvent],
    watch: {
        $route(to) {
            this.Path = to.fullPath;
        }
    },
    methods: {
        router($event, path) {
            if(!this.touchEnd($event)) return;
            if(this.Path === path) return;
            router.push(path);
            this.gaEvent('footer_nav_click', path);
        },
        gaEvent(cate, label) {
            this.$ga.event(cate, 'click', label);
        },
        thisTimer: function () {
            // 特別戻らない使用にする
            if(this.Path === '/interactiveMovie' || this.Path === '/vr') return;
            if(this.Path !== '/') {
                if(this.backHomeToggle) return;
                router.push('/');
                setTimeout(() => {
                    this.$refs.routerView.topOverlayOn();
                }, 10);
                return;
            } else {
                setTimeout(() => {
                    if(this.backHomeToggle) return;
                    this.$refs.routerView.topScheduleClose(this.modalClose);
                    this.$refs.routerView.topGoodsClose(this.modalClose);
                    this.$refs.routerView.guestModalClose(this.modalClose);
                    this.$refs.routerView.topOverlayOn();
                }, 100);
            }
        },
        clickOn() {
            this.clickAction = true;
            this.backHomeToggle = true;
        },
        clickFalse(toggle) {
            this.clickAction = toggle;
        },
        alert(e) {
            e.preventDefault();
            e.returnValue = '';
        },
        keepDataExport(val) {
            this.media = val;
        },
        keepDataInport() {
            if(this.Path !== '/') return;

            if(this.media) this.$refs.routerView.keepDataInport(this.media);

            if(!this.media) this.$refs.routerView.keepDataInport(false);
        },
    },
    created() {
    },
    mounted() {
        this.keepDataInport();
        if(this.Path === '/') this.$refs.routerView.topOverlayOn();

        setInterval(() => {
            if(!this.backHomeToggle) {
                this.thisTimer();
            }
            this.backHomeToggle = false;
        }, 60000);

        window.addEventListener('beforeunload', this.alert);
    },
    updated() {
        this.keepDataInport();
    },
    beforeDestroy() {
        window.removeEventListener('beforeunload', this.alert);
    },
}
</script>

<style>
html {
    font-size: 62.5%;
}
body {
    color: #4B4B4B;
    font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Yu Gothic", "YuGothic", "Meiryo", sans-serif;
    font-family: source-han-sans-japanese, sans-serif;
    font-style: normal;
    font-style: normal;
    font-family: 'Roboto', 'Noto Sans JP', sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
}
body * {
    -webkit-tap-highlight-color:rgba(0,0,0,0);
    -webkit-user-select: none;
    user-select: none;
}
a, ul, li, body, section, div, p, span, svg, h1, h2, h3, h4, h5, h6 {
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 1;
}
@media screen and (min-width:768px) {
    body {
        font-size: 1.6rem;
    }
}
@media screen and (max-width:767px) {
    body {
        font-size: 1.4rem;
    }
}
@media screen and (min-width:768px) {
}
@media screen and (max-width:767px) {
}
@media all and (-ms-high-contrast: none) {
    /* IE用スタイル */
}

/******************************************
 共通部
******************************************/
:root {
    --mainColor: #096FC8;
    --headerTextColor: #FFF;
    --listBgColor: #FFF;
    --grayColor: #F0F0F0;
}
#app {
    width: 1080px;
    margin: 0 auto;
    position: relative;
}
.signage,
.views__container {
    width: 1080px;
    height: calc(1920px - 132px);
    position: relative;
    background-color: #F7F7F7;
    overflow: hidden;
}
.signage::before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 177.777778%;
}
.signage--inner {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}


</style>
