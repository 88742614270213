<template>
  <div class="snnualSchedule views__container">
    <div class="views__container--head">
      年間スケジュール
      <a v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="router($event, '/')" class="back__btn linkElm">
        <div class="back__btn--icon">
          <img class="back__btn--icon--img" src="../..//public/icon/svg/home.svg" alt="">
        </div>
        <div class="back__btn--title">TOPへ戻る</div>
      </a>
    </div>
    <div class="views__container--inner annualSchedule__container zoom">
      <div class="annualSchedule__container--inner zoom__elm">
        <div class="annualSchedule__img--box">
          <img class="annualSchedule__img" src="../../public/img/13views/calendar.jpg" alt="">
        </div>
        <div class="annualSchedule__points--box">
          <input type="radio" v-model="selectItem" value="15" class="annualSchedule__points--radio" id="selectItem01">
          <label v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="modalOpen($event, '15')" for="selectItem01" class="annualSchedule__points selectItem01 linkElm">
            <span class="annualSchedule__points--icon"></span>
          </label>
        </div>
      </div>
    </div>
    <topScheduleModal v-if="itemList[0]" v-show="modalToggle" v-bind:val="itemList[0]" v-on:scheduleModalClose="scheduleClose"></topScheduleModal>
  </div>
</template>

<script>
import router from '../router'
import { HOST } from '../mixin.js'
import panzoom from '../panzoom/'
import { touchEvent, linkEvent } from '../mixin02.js'

// modal
import topScheduleModal from '../components/topScheduleModal'

export default {
  data() {
    return{
      todayScheduleList: [],
      itemList: "",
      selectItem: "",
      modalToggle: false,

      zoomElm: document.querySelector(".zoom__elm"),
      panZoom: null,
    }
  },
  mixins: [touchEvent, linkEvent],
  components: {
    topScheduleModal,
  },
  methods: {
    router($event, path) {
      if(!this.touchEnd($event)) return;
      router.push(path);
    },
    getSchedule() {
      this.$axios
        .get(HOST + '/wp-json/wp/v2/todeayexhibition?_embed')
        .then( response => {
          for( let item of response.data ) {
            this.todayScheduleList.push({
                id: item.id,
                title: item.title.rendered,
                content: item.content.rendered,
                excerpt: item.excerpt.rendered,
                thum: item._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url,
                thumAlt: item._embedded['wp:featuredmedia'][0].alt_text,
                term: item._embedded['wp:term'][0][0].slug,
                customField: item.acf.todayexhibitiontime,
                customFieldPeriod: item.acf.period,
                customFieldVenue: item.acf.venue,
                customFieldPrice00: item.acf.price00,
                customFieldPrice00text: item.acf.price00text,
                customFieldPrice01: item.acf.price01,
                customFieldPrice02: item.acf.price02,
                customFieldPrice03: item.acf.price03,
                customFieldPrice04: item.acf.price04,
            })
          }
        })
    },
    modalOpen($event, value) {
      if(!this.touchEnd($event)) return;
      if(value) this.selectItem = value;
      setTimeout(() => {
        this.itemList = this.todayScheduleList.filter((item) => item.id == this.selectItem);
        this.modalToggle = true;
      }, 10);
    },
    scheduleClose() {
      this.modalToggle = false;
    },
    zoomOn() {
        setTimeout(() => {
            this.zoomElm = document.querySelector(".zoom__elm");
            this.panZoom = panzoom(this.zoomElm, {
                pinchSpeed: 1.0,
                initialZoom: 1.0,
                maxZoom: 3.0,
                minZoom: 1.0,
                bounds: true,
                boundsPadding: 1.0,
            });
        }, 10);
    },
    zoomOff() {
        this.panZoom.dispose();
    }
  },
  created() {

  },
  mounted() {
    this.getSchedule();
    this.zoomOn();
  },
  destroyed() {
    this.zoomOff();
  }
}


</script>

<style>
.annualSchedule__container {
}
.annualSchedule__container--inner {
  position: relative;
  width: 100%;
  height: 100%;
}
.annualSchedule__img--box {
  width: 100%;
  height: 100%;
}
.annualSchedule__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.annualSchedule__points--box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.annualSchedule__points--radio {
  display: none;
}
.annualSchedule__points {
  display: block;
  width: 200px;
  height: 255px;
  box-sizing: border-box;
  /* background-color: rgba(255,255,0,0.1); */
  /* border: solid 1px rgba(255,255,0,0.5); */
  position: absolute;
}
.annualSchedule__points.selectItem01 {
  top: 28%;
  left: 21%;
}
.annualSchedule__points--icon {
  display: block;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 79%;
  left: 80%;
  transform: translate(-50%,-50%);
}
.annualSchedule__points--icon::before,
.annualSchedule__points--icon::after {
   content: "";
   display: block;
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   border-radius: 100%;
   background-color: var(--mainColor);
   opacity: 0.9;
   transform: scale(0);
}
.annualSchedule__points--icon::before {
  animation: touchAnimation 2.5s ease-out 0s infinite running;
}
.annualSchedule__points--icon::after {
  animation: touchAnimation 2.5s ease-out 0.6s infinite running;
}
@keyframes touchAnimation {
  0% {
    opacity: 0.9;
    transform: scale(0);
  } 100% {
    opacity: 0;
    transform: scale(1);
  }
}

</style>