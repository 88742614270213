<template>
    <div class="headAdArea">
        <!-- <topSlider03 ref="topSlider02" v-on:fullAddModalOpen01="fullAddModalOpen" v-bind:val="videoRestartToggle"></topSlider03> -->
        <!-- <topSlider05 ref="topSlider02" v-on:fullAddModalOpen01="fullAddModalOpen" v-bind:val="videoRestartToggle"></topSlider05> -->
        <!-- <topSlider06 ref="topSlider02" v-on:fullAddModalOpen01="fullAddModalOpen" v-bind:val="videoRestartToggle"></topSlider06> -->
        <topSlider07 ref="topSlider02" v-on:keepDataExport="keepDataExport" v-on:fullAddModalOpen01="fullAddModalOpen" v-bind:val="videoRestartToggle"></topSlider07>
    </div>
</template>
<script>
// import topSlider03 from './topSlider03'
// import topSlider05 from './topSlider05'
// import topSlider06 from './topSlider06'
import topSlider07 from './topSlider07'

export default {
    name: 'topMovieArea',
    data() {
        return{
            fullPageAddToggle: false,
            videoRestartToggle: false,

            clickAction: false,
        }
    },
    props: ['val'],
    watch: {
        val: function(newval) {
            this.videoRestartToggle = newval;
        },
    },
    components: {
        // topSlider03,
        // topSlider05,
        // topSlider06,
        topSlider07,
    },
    methods: {
        fullAddModalOpen() {
            this.fullPageAddToggle = true;
            this.$emit('fullAddModalOpen02', this.fullPageAddToggle);
            this.videoRestartToggle = false;
        },
        keepDataExport(val) {
            this.$emit('keepDataExport', val);
        },
        keepDataInport(val) {
            this.$refs.topSlider02.keepDataInport(val);
        },
    },
}
</script>

<style>
/******************************************
 トップアドエリア
******************************************/
.headAdArea__section {
    width: 100%;
    /* height: 31.510417%; */
    height: 608px;
}
.headAdArea {
    width: 100%;
    height: 100%;
    position: relative;
}
.headAdArea__toggle {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%,0);
    width: 100px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-weight: 500;
    border: #aaa;
    border-radius: 4px;
    background-color: rgba(255,255,255,0.6);
    color: #555;
    z-index: 10;
}
</style>