<template>
    <div class="views__container">
        <div class="views__container--head">
            ご利用案内
            <a v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="router($event, '/')" class="back__btn linkElm">
                <div class="back__btn--icon">
                <img class="back__btn--icon--img" src="../..//public/icon/svg/home.svg" alt="">
                </div>
                <div class="back__btn--title">TOPへ戻る</div>
            </a>
        </div>
        <div class="views__container--inner">
            <div class="minPage__container info__container">
                <div class="minPage__block flex">
                    <div class="minPage__block--left">
                        <div class="minPage__block--title">開館時間</div>
                    </div>
                    <div class="minPage__block--right">
                        <div class="minPage__block--content">
                            <div class="minPage__block--content--dl--outer">
                                <dl class="minPage__block--content--dl">
                                    <dt class="minPage__block--content--dt">
                                        開館時間
                                    </dt>
                                    <dd class="minPage__block--content--dd">
                                        10:00～18:00(展示室への入室は17:30まで)
                                    </dd>
                                </dl>
                                <dl class="minPage__block--content--dl">
                                    <dt class="minPage__block--content--dt">
                                        休館日
                                    </dt>
                                    <dd class="minPage__block--content--dd">
                                        <span class="minPage__block--content--dd--span">
                                            月曜日(祝休日を除く)
                                        </span>
                                        <span class="minPage__block--content--dd--span">
                                            祝休日の翌平日
                                        </span>
                                        <span class="minPage__block--content--dd--span">
                                            年末年始
                                        </span>
                                        <span class="minPage__block--content--dd--span">
                                            特別整理期間
                                        </span>
                                    </dd>
                                </dl>
                            </div>
                            <p class="minPage__block--content--p">
                                ※臨時に開・休館することがあります。
                            </p>
                        </div>
                    </div>
                </div>
                <div class="minPage__block flex">
                    <div class="minPage__block--left">
                        <div class="minPage__block--title">観覧料</div>
                    </div>
                    <div class="minPage__block--right">
                        <div class="minPage__block--content">
                            <ul class="minPage__block--content--ul">
                                <li class="minPage__block--content--li">
                                    コレクション展は観覧無料です
                                </li>
                                <li class="minPage__block--content--li">
                                    特別展はそのつど定めます
                                </li>
                                <li class="minPage__block--content--li">
                                    アートシアター、映像ブース、美術図書室の利用は無料
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="minPage__block flex">
                    <div class="minPage__block--left">
                        <div class="minPage__block--title">団体でのご利用</div>
                    </div>
                    <div class="minPage__block--right">
                        <div class="minPage__block--content">
                            <p class="minPage__block--content--p">
                                団体での利用 学校等で美術館をご利用いただく場合 ( コレクション展 、 特別展鑑賞) は申請書をご提出ください。<br
                                >申請書は、美術館 (1階 インフォメーション) に持参していただくか、 FAXで(0985-20-3796)学校団体担当宛てにお送りください。
                            </p>
                        </div>
                    </div>
                </div>
                <div class="minPage__block flex">
                    <div class="minPage__block--left">
                        <div class="minPage__block--title">館見学ツアー</div>
                    </div>
                    <div class="minPage__block--right">
                        <div class="minPage__block--content">
                            <p class="minPage__block--content--p">
                                宮崎県立美術館、宮崎県立図書館、宮崎県立芸術劇場及び宮崎県総合博物館の4館をめぐって宮崎の魅力を実感・再発見するツアーです。<br
                                >お申込み、ご相談は総合受付窓口の宮崎県総合博物館まで。<br
                                >TEL 0985-24-2071 / FAX 0985-24-2199
                            </p>
                        </div>
                    </div>
                </div>
                <div class="minPage__block flex">
                    <div class="minPage__block--left">
                        <div class="minPage__block--title">バリアフリー情報</div>
                    </div>
                    <div class="minPage__block--right">
                        <div class="minPage__block--content">
                            <h3 class="minPage__block--content--h3">地下進入路</h3>
                            <p class="minPage__block--content--p">
                                車いすを利用されるなど足の不自由な方には、美術館地下駐車場の利用をお勧めします。（利用状況によってはご利用になれないこともあります。）なお、業務用の車両も出入りしますので、ご利用の際にはご注意ください。<br
                                >建物内の設備等につきましては、こちらからご確認ください。
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import router from '../router'
import { touchEvent, linkEvent } from '../mixin02.js'
export default {
    data() {
        return{

        }
    },
    mixins: [touchEvent, linkEvent],
    methods: {
        router($event, path) {
            if(!this.touchEnd($event)) return;
            router.push(path);
        },
    },
}
</script>

<style>
/* 共通内容 */
.minPage__container {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 60px 0 0 0;
}
.minPage__block {
    width: 100%;
    margin: 0 0 45px 0;
    background-color: var(--listBgColor);
    padding: 40px;
    border-radius: 10px;
    border: solid 3px var(--mainColor);
}
.minPage__block.flex {
    display: flex;
    gap: 0 21px;
}
.minPage__block:nth-last-child(1) {
    margin-bottom: 0;
}
.minPage__block--left {
    width: 205px;
}
.minPage__block--title {
    height: 100%;
    font-size: 2.1rem;
    font-weight: 600;
    color: var(--mainColor);
    position: relative;
    display: flex;
    align-items: center;
}
.minPage__block--title::before {
    content: "";
    display: block;
    width: 2px;
    height: 100%;
    border-radius: 5px;
    background-color: var(--mainColor);
    position: absolute;
    top: 0;
    right: 0;
}
.minPage__block--right {
    width: calc(100% - 205px - 21px);
}
.minPage__block--content {
    font-size: 1.5rem;
    font-weight: 500;
}
.minPage__block--content > *:nth-last-child(1) {
    margin-bottom: 0;
}
.minPage__block--content--p {
    line-height: 1.5;
    margin: 0 0 0.8em;
}
.minPage__block--content--h3 {
    font-size: 1.7rem;
    font-weight: 600;
    line-height: 1.2;
    margin: 0 0 0.4em 0;
    color: var(--mainColor);
}
.minPage__block--content--dl--outer {
    margin: 0 0 0.8em 0;
}
.minPage__block--content--dl {
    margin: 0 0 0.3em 0;
    display: flex;
}
.minPage__block--content--dt {
    line-height: 1.5;
    margin: 0 0 0 0;
    width: 100px;
}
.minPage__block--content--dd {
    line-height: 1.5;
    margin: 0 0 0 0;
    width: calc(100% - 100px);
}
.minPage__block--content--dt.wid140 {
    width: 140px;
}
.minPage__block--content--dd.wid140 {
    width: (100% - 140px);
}
.minPage__block--content--dd--span {
    line-height: 1.5;
    display: block;
    margin: 0 0 0.1em 0;
}
.minPage__block--content--dd--span:nth-last-child(1) {
    margin-bottom: 0;
}
.minPage__block--content--dd--span::before {
    content: "・";
}
.minPage__block--content--ul {
    padding-left: 20px;
}
.minPage__block--content--li {
    line-height: 1.5;
    list-style: disc;
    margin: 0 0 0.3rem 0;
}
.minPage__block--content--li:nth-last-child(1) {
    margin-bottom: 0;
}
/* 共通内容 ここまで */


</style>