<template>
    <div v-on:touchend.self="modalClose" class="gameContentModal overlay">
        <div class="mainContent__list topGoodsModal">
            <div class="mainContent__list--head modal">
                クロスワードチャレンジ実施中！
                <div v-on:touchend="modalClose" class="modal__close--btn linkElm" v-on:touchstart="addClass($event)">
                    <span class="modal__close--btn--inner modal__close--btn--inner01"></span>
                    <span class="modal__close--btn--inner modal__close--btn--inner02"></span>
                </div>
            </div>
            <div class="mainContent__list--content--outer--model">
                <div class="gameContentModal__container">
                    <img class="gameContentModal__img" src="https://stdichigeki.com/signage/wp-content/uploads/2021/09/10クロスワードバナー-min.jpg" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { linkEvent } from '../mixin02.js'
export default {
    data() {
        return{
            gameContentModalToggle: false,
        }
    },
    mixins: [linkEvent],
    methods: {
        modalClose() {
            this.$emit('gameContentModalClose', this.gameContentModalToggle);
        },
    }
}
</script>

<style>
.gameContentModal {
    width: 100%;
    height: 100%;
}
.gameContentModal__container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 8px;
}
.gameContentModal__img {
    display: block;
    width: 100%;
    height: auto;
}


</style>