
export const touchEvent = {
    data() {
        return{
            originPoint: {},
        }
    },
    methods: {
        touchOn($event) {
            var _originPoint = {
                x: $event.changedTouches[0].clientX,
                y: $event.changedTouches[0].clientY
            };
            this.originPoint = _originPoint;
        },
        touchEnd($event) {
            var _currentPoint = {
                x: $event.changedTouches[0].clientX,
                y: $event.changedTouches[0].clientY
            };
            var X = this.originPoint.x - _currentPoint.x;
            var Y = this.originPoint.y - _currentPoint.y;
            var distance = Math.sqrt(Math.pow(X, 2) + Math.pow(Y, 2));
            // console.log("distance", distance);
            if (distance <= 20) return true;
            return false;
        },
    },
}

export const linkEvent = {
    data() {
        return {
        }
    },
    methods: {
        addClass($event) {
            var _this = this;
            var _target = $event.target;
            _target.classList.add('active');

            _target.addEventListener('pointerleave' , function () {
                _this.removeClass(_target);
            },{once:true});

        },
        removeClass(_target) {
            if (!_target.classList.contains('active')) return;
            _target.classList.remove('active');
        },
    },
}
