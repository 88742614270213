<template>
  <div class="doods views__container">
    <div class="views__container--head">
      縦型表示
      <a v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="router($event, '/')" class="back__btn linkElm">
        <div class="back__btn--icon">
          <img class="back__btn--icon--img" src="../..//public/icon/svg/home.svg" alt="">
        </div>
        <div class="back__btn--title">TOPへ戻る</div>
      </a>
    </div>
    <div class="views__container--inner fulladd">
      <div class="views__container--inner--bar fulladd">
        <div class="fulladd__container">
          <!-- 繰り返し -->
          <div v-for="item in fulladdList" v-bind:key="item.name" v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="openModal($event, item)" class="fulladd__Content--list fulladd__page linkElm">
              <div class="fulladd__Content--imgBox">
                  <img v-bind:src="item.thumSrc" class="fulladd__Content--img">
              </div>
              <div v-html="item.name" class="fulladd__Content--title"></div>
          </div>
          <!-- 繰り返しここまで -->
        </div>
      </div>
    </div>
    <!-- modal -->
    <fulladdSample ref="fulladdSample" v-on:fulladdClose="fulladdClose" v-bind:val="fulladdData" v-show="fulladdToggle" ></fulladdSample>
    <!-- modal end -->
  </div>
</template>

<script>
import router from '../router'
import fulladdSample from '../components/fulladdSample'
import { touchEvent, linkEvent } from '../mixin02.js'
export default {
    name: 'topGoodsList',
    data() {
        return {
            fulladdToggle: false,
            fulladdData: "",
            // ========================
            fulladdList:[
              {
                name: "天気予報01",
                type: "html",
                src: "https://ez1.nfits-i.com/service/pub/preview-ex.php?sn=FRE-STI-14-01&noLoop",
                thumSrc: "./img/15fulladd/whether01.jpg",
              },
              // {
              //   name: "天気予報02(hoyahoya)",
              //   type: "html",
              //   src: "https://hoyahoya.app/output/fd12de4f6a2531314b90daa084246874",
              //   thumSrc: "./img/15fulladd/whether02.jpg",
              // },
              {
                name: "天気予報02(横型)",
                type: "html",
                src: "https://www.nds-tyo.co.jp/pro/Nfits/contest/preview-ex.php?sn=DM-000-0-00",
                thumSrc: "./img/15fulladd/whether03.jpg",
              },
              {
                name: "縦型動画01(標準)",
                type: "video",
                src: "./video/sample11.mp4",
                thumSrc: "./img/15fulladd/01video.png",
              },
              {
                name: "縦型動画02(標準)",
                type: "video",
                src: "./video/sample01.mp4",
                thumSrc: "./img/15fulladd/02video.png",
              },
              {
                name: "縦型動画03(高画質)",
                type: "video",
                src: "./video/sample12.mp4",
                thumSrc: "./img/15fulladd/03video.png",
              },
              {
                name: "縦型動画04(標準)",
                type: "video",
                src: "./video/sample13.mp4",
                thumSrc: "./img/15fulladd/03video.png",
              },
            ]
            // ========================
        }
    },
    mixins: [touchEvent, linkEvent],
    components: {
      fulladdSample,
    },
    methods: {
        router($event, path) {
          if(!this.touchEnd($event)) return;
          router.push(path);
        },
        fulladdClose(toggle) {
            this.fulladdToggle = toggle;
            this.fulladdData = "";
        },
        // modal
        openModal($event, item) {
          if(!this.touchEnd($event)) return;
          this.fulladdToggle = true;
          this.fulladdData = item;
          this.$refs.fulladdSample.fullAddToggle();
        },
    },
    created: function() {
    }
}
</script>

<style>
/* グッズ */
.fulladd__Content--list {
    width: calc(33.3333% - 16px);
}
.fulladd__Content--imgBox {
    width: 100%;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    border: solid 1px #f7f7f7;
}
.fulladd__Content--imgBox::before {
    content: "";
    display: block;
    widows: 100%;
    padding-top: 177.8%;
}
.fulladd__Content--img {
    width: 100%;
    height: 100%;
    /* object-fit: contain; */
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}
.fulladd__Content--title {
    margin: 12px 0 0 0;
    font-size: 1.6rem;
    font-weight: 500;
}

.views__container--inner.fulladd {
  padding: 0 10px 30px 0;
}
.views__container--inner--bar.fulladd {
  width: 100%;
  height: 100%;
  overflow: overlay;
}
.views__container--inner--bar.fulladd::-webkit-scrollbar {
    width: 12px;
}
.views__container--inner--bar.fulladd::-webkit-scrollbar-track {
    margin: 3px 0;
}
.views__container--inner--bar.fulladd::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #D3D3D3;
}
.fulladd__container {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  padding: 40px 0 0 0;
}
.mainContent__list--products--list.goods__page {
  width: calc(25% - 18px);
}
</style>