export const HOST = 'https://stdichigeki.com/signage';
 
export const getPostOption = {
    methods: {
        getDate: date => {
            return date.replace(/T.*/, '')
        },
        getExcerpt: (excerpt, num) => {
            return excerpt.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '').substr(0, num)+' <span>...</span>'
        },
    }
}
