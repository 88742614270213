<template>
    <div class="busTimeTable">
        <div class="busTimeTable__title">マップからバス停を選択してください</div>
        <div class="busTimeTable__map--box">
            <div class="busTimeTable__map">
                <img src="../../public/img/11bus/busmap.png" alt="">
            </div>
            <div class="busTimeTable__points--box">
                <label for="busStop01" class="busTimeTable__point busStop01 linkElm" v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="busModelOpen($event, 'busStop01')">
                    <svg version="1.1" x="0px" y="0px" viewBox="0 0 50 75">
                        <path id="pin-icon" d="M25,0C11.9,0,0,10.6,0,23.8C0,43.3,19.9,45.3,25,75c5.1-29.7,25-31.7,25-51.2C50,10.6,38.1,0,25,0z"/>
                        <g id="bus-icon">
                            <path class="st0" d="M19.4,15.3h11.1c0.4,0,0.8-0.4,0.8-0.8v-1c0-0.4-0.4-0.8-0.8-0.8H19.4c-0.4,0-0.8,0.4-0.8,0.8v1
                                C18.6,15,19,15.3,19.4,15.3z"/>
                            <path class="st0" d="M17.7,26.1h14.7c0.4,0,0.8-0.4,0.8-0.8v-7.5c0-0.4-0.4-0.8-0.8-0.8H17.7c-0.4,0-0.8,0.4-0.8,0.8v7.5
                                C16.9,25.7,17.2,26.1,17.7,26.1z M18.5,18.5h13.1v5.9H18.5V18.5z"/>
                            <path class="st0" d="M37.7,16.4h-0.8v-2.3c0-2.1-1.7-3.8-3.8-3.8H16.9c-2.1,0-3.8,1.7-3.8,3.8v2.3h-0.8c-0.8,0-1.3,0.6-1.3,1.3v5.6
                                c0,0.8,0.6,1.3,1.3,1.3h0.8v9.7c0,0.4,0.4,0.8,0.8,0.8h0.2v0.8c0,0.8,0.7,1.5,1.5,1.5h3.1c0.4,0,0.8-0.2,1.1-0.5
                                c0.2-0.3,0.4-0.7,0.3-1v-0.8h9.5v0.8c0,0.8,0.7,1.5,1.5,1.5h3.1c0.4,0,0.8-0.2,1.1-0.5c0.2-0.3,0.4-0.7,0.3-1v-0.8h0.4
                                c0.4,0,0.8-0.4,0.8-0.8v-9.7h0.8c0.7,0,1.3-0.6,1.3-1.4v-5.5C39,16.9,38.4,16.4,37.7,16.4z M14.7,14.1c0-1.2,1-2.2,2.2-2.2h16.1
                                c1.2,0,2.2,1,2.2,2.2v3.1v6.6v9.7h-0.4h-4.4H19.4h-4.4h-0.2v-9.7v-6.6V14.1z M12.6,23v-5h0.5v5H12.6z M18.6,35.8h-2.9v-0.7h2.9
                                V35.8z M34.1,35.8h-2.9v-0.7h2.9V35.8z M37.4,23h-0.5v-5h0.5V23z"/>
                            <path class="st0" d="M19.4,27.6c-1.3,0-2.3,1-2.3,2.3s1,2.3,2.3,2.3c1.3,0,2.3-1,2.3-2.3S20.7,27.6,19.4,27.6z M19.4,30.6
                                c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7S19.8,30.6,19.4,30.6z"/>
                            <path class="st0" d="M30.5,27.6c-1.3,0-2.3,1-2.3,2.3s1,2.3,2.3,2.3s2.3-1,2.3-2.3S31.8,27.6,30.5,27.6z M30.5,30.6
                                c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7s0.7,0.3,0.7,0.7S30.9,30.6,30.5,30.6z"/>
                        </g>
                    </svg>
                </label>
                <label for="busStop02" class="busTimeTable__point busStop02 linkElm" v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="busModelOpen($event, 'busStop02')">
                    <svg version="1.1" x="0px" y="0px" viewBox="0 0 50 75">
                        <path id="pin-icon" d="M25,0C11.9,0,0,10.6,0,23.8C0,43.3,19.9,45.3,25,75c5.1-29.7,25-31.7,25-51.2C50,10.6,38.1,0,25,0z"/>
                        <g id="bus-icon">
                            <path class="st0" d="M19.4,15.3h11.1c0.4,0,0.8-0.4,0.8-0.8v-1c0-0.4-0.4-0.8-0.8-0.8H19.4c-0.4,0-0.8,0.4-0.8,0.8v1
                                C18.6,15,19,15.3,19.4,15.3z"/>
                            <path class="st0" d="M17.7,26.1h14.7c0.4,0,0.8-0.4,0.8-0.8v-7.5c0-0.4-0.4-0.8-0.8-0.8H17.7c-0.4,0-0.8,0.4-0.8,0.8v7.5
                                C16.9,25.7,17.2,26.1,17.7,26.1z M18.5,18.5h13.1v5.9H18.5V18.5z"/>
                            <path class="st0" d="M37.7,16.4h-0.8v-2.3c0-2.1-1.7-3.8-3.8-3.8H16.9c-2.1,0-3.8,1.7-3.8,3.8v2.3h-0.8c-0.8,0-1.3,0.6-1.3,1.3v5.6
                                c0,0.8,0.6,1.3,1.3,1.3h0.8v9.7c0,0.4,0.4,0.8,0.8,0.8h0.2v0.8c0,0.8,0.7,1.5,1.5,1.5h3.1c0.4,0,0.8-0.2,1.1-0.5
                                c0.2-0.3,0.4-0.7,0.3-1v-0.8h9.5v0.8c0,0.8,0.7,1.5,1.5,1.5h3.1c0.4,0,0.8-0.2,1.1-0.5c0.2-0.3,0.4-0.7,0.3-1v-0.8h0.4
                                c0.4,0,0.8-0.4,0.8-0.8v-9.7h0.8c0.7,0,1.3-0.6,1.3-1.4v-5.5C39,16.9,38.4,16.4,37.7,16.4z M14.7,14.1c0-1.2,1-2.2,2.2-2.2h16.1
                                c1.2,0,2.2,1,2.2,2.2v3.1v6.6v9.7h-0.4h-4.4H19.4h-4.4h-0.2v-9.7v-6.6V14.1z M12.6,23v-5h0.5v5H12.6z M18.6,35.8h-2.9v-0.7h2.9
                                V35.8z M34.1,35.8h-2.9v-0.7h2.9V35.8z M37.4,23h-0.5v-5h0.5V23z"/>
                            <path class="st0" d="M19.4,27.6c-1.3,0-2.3,1-2.3,2.3s1,2.3,2.3,2.3c1.3,0,2.3-1,2.3-2.3S20.7,27.6,19.4,27.6z M19.4,30.6
                                c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7S19.8,30.6,19.4,30.6z"/>
                            <path class="st0" d="M30.5,27.6c-1.3,0-2.3,1-2.3,2.3s1,2.3,2.3,2.3s2.3-1,2.3-2.3S31.8,27.6,30.5,27.6z M30.5,30.6
                                c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7s0.7,0.3,0.7,0.7S30.9,30.6,30.5,30.6z"/>
                        </g>
                    </svg>
                </label>
                <label for="busStop03" class="busTimeTable__point busStop03 linkElm" v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="busModelOpen($event, 'busStop03')">
                    <svg version="1.1" x="0px" y="0px" viewBox="0 0 50 75">
                        <path id="pin-icon" d="M25,0C11.9,0,0,10.6,0,23.8C0,43.3,19.9,45.3,25,75c5.1-29.7,25-31.7,25-51.2C50,10.6,38.1,0,25,0z"/>
                        <g id="bus-icon">
                            <path class="st0" d="M19.4,15.3h11.1c0.4,0,0.8-0.4,0.8-0.8v-1c0-0.4-0.4-0.8-0.8-0.8H19.4c-0.4,0-0.8,0.4-0.8,0.8v1
                                C18.6,15,19,15.3,19.4,15.3z"/>
                            <path class="st0" d="M17.7,26.1h14.7c0.4,0,0.8-0.4,0.8-0.8v-7.5c0-0.4-0.4-0.8-0.8-0.8H17.7c-0.4,0-0.8,0.4-0.8,0.8v7.5
                                C16.9,25.7,17.2,26.1,17.7,26.1z M18.5,18.5h13.1v5.9H18.5V18.5z"/>
                            <path class="st0" d="M37.7,16.4h-0.8v-2.3c0-2.1-1.7-3.8-3.8-3.8H16.9c-2.1,0-3.8,1.7-3.8,3.8v2.3h-0.8c-0.8,0-1.3,0.6-1.3,1.3v5.6
                                c0,0.8,0.6,1.3,1.3,1.3h0.8v9.7c0,0.4,0.4,0.8,0.8,0.8h0.2v0.8c0,0.8,0.7,1.5,1.5,1.5h3.1c0.4,0,0.8-0.2,1.1-0.5
                                c0.2-0.3,0.4-0.7,0.3-1v-0.8h9.5v0.8c0,0.8,0.7,1.5,1.5,1.5h3.1c0.4,0,0.8-0.2,1.1-0.5c0.2-0.3,0.4-0.7,0.3-1v-0.8h0.4
                                c0.4,0,0.8-0.4,0.8-0.8v-9.7h0.8c0.7,0,1.3-0.6,1.3-1.4v-5.5C39,16.9,38.4,16.4,37.7,16.4z M14.7,14.1c0-1.2,1-2.2,2.2-2.2h16.1
                                c1.2,0,2.2,1,2.2,2.2v3.1v6.6v9.7h-0.4h-4.4H19.4h-4.4h-0.2v-9.7v-6.6V14.1z M12.6,23v-5h0.5v5H12.6z M18.6,35.8h-2.9v-0.7h2.9
                                V35.8z M34.1,35.8h-2.9v-0.7h2.9V35.8z M37.4,23h-0.5v-5h0.5V23z"/>
                            <path class="st0" d="M19.4,27.6c-1.3,0-2.3,1-2.3,2.3s1,2.3,2.3,2.3c1.3,0,2.3-1,2.3-2.3S20.7,27.6,19.4,27.6z M19.4,30.6
                                c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7S19.8,30.6,19.4,30.6z"/>
                            <path class="st0" d="M30.5,27.6c-1.3,0-2.3,1-2.3,2.3s1,2.3,2.3,2.3s2.3-1,2.3-2.3S31.8,27.6,30.5,27.6z M30.5,30.6
                                c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7s0.7,0.3,0.7,0.7S30.9,30.6,30.5,30.6z"/>
                        </g>
                    </svg>
                </label>
                <label for="busStop04" class="busTimeTable__point busStop04 linkElm" v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="busModelOpen($event, 'busStop04')">
                    <svg version="1.1" x="0px" y="0px" viewBox="0 0 50 75">
                        <path id="pin-icon" d="M25,0C11.9,0,0,10.6,0,23.8C0,43.3,19.9,45.3,25,75c5.1-29.7,25-31.7,25-51.2C50,10.6,38.1,0,25,0z"/>
                        <g id="bus-icon">
                            <path class="st0" d="M19.4,15.3h11.1c0.4,0,0.8-0.4,0.8-0.8v-1c0-0.4-0.4-0.8-0.8-0.8H19.4c-0.4,0-0.8,0.4-0.8,0.8v1
                                C18.6,15,19,15.3,19.4,15.3z"/>
                            <path class="st0" d="M17.7,26.1h14.7c0.4,0,0.8-0.4,0.8-0.8v-7.5c0-0.4-0.4-0.8-0.8-0.8H17.7c-0.4,0-0.8,0.4-0.8,0.8v7.5
                                C16.9,25.7,17.2,26.1,17.7,26.1z M18.5,18.5h13.1v5.9H18.5V18.5z"/>
                            <path class="st0" d="M37.7,16.4h-0.8v-2.3c0-2.1-1.7-3.8-3.8-3.8H16.9c-2.1,0-3.8,1.7-3.8,3.8v2.3h-0.8c-0.8,0-1.3,0.6-1.3,1.3v5.6
                                c0,0.8,0.6,1.3,1.3,1.3h0.8v9.7c0,0.4,0.4,0.8,0.8,0.8h0.2v0.8c0,0.8,0.7,1.5,1.5,1.5h3.1c0.4,0,0.8-0.2,1.1-0.5
                                c0.2-0.3,0.4-0.7,0.3-1v-0.8h9.5v0.8c0,0.8,0.7,1.5,1.5,1.5h3.1c0.4,0,0.8-0.2,1.1-0.5c0.2-0.3,0.4-0.7,0.3-1v-0.8h0.4
                                c0.4,0,0.8-0.4,0.8-0.8v-9.7h0.8c0.7,0,1.3-0.6,1.3-1.4v-5.5C39,16.9,38.4,16.4,37.7,16.4z M14.7,14.1c0-1.2,1-2.2,2.2-2.2h16.1
                                c1.2,0,2.2,1,2.2,2.2v3.1v6.6v9.7h-0.4h-4.4H19.4h-4.4h-0.2v-9.7v-6.6V14.1z M12.6,23v-5h0.5v5H12.6z M18.6,35.8h-2.9v-0.7h2.9
                                V35.8z M34.1,35.8h-2.9v-0.7h2.9V35.8z M37.4,23h-0.5v-5h0.5V23z"/>
                            <path class="st0" d="M19.4,27.6c-1.3,0-2.3,1-2.3,2.3s1,2.3,2.3,2.3c1.3,0,2.3-1,2.3-2.3S20.7,27.6,19.4,27.6z M19.4,30.6
                                c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7S19.8,30.6,19.4,30.6z"/>
                            <path class="st0" d="M30.5,27.6c-1.3,0-2.3,1-2.3,2.3s1,2.3,2.3,2.3s2.3-1,2.3-2.3S31.8,27.6,30.5,27.6z M30.5,30.6
                                c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7s0.7,0.3,0.7,0.7S30.9,30.6,30.5,30.6z"/>
                        </g>
                    </svg>
                </label>
                <label for="busStop05" class="busTimeTable__point busStop05 linkElm" v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="busModelOpen($event, 'busStop05')">
                    <svg version="1.1" x="0px" y="0px" viewBox="0 0 50 75">
                        <path id="pin-icon" d="M25,0C11.9,0,0,10.6,0,23.8C0,43.3,19.9,45.3,25,75c5.1-29.7,25-31.7,25-51.2C50,10.6,38.1,0,25,0z"/>
                        <g id="bus-icon">
                            <path class="st0" d="M19.4,15.3h11.1c0.4,0,0.8-0.4,0.8-0.8v-1c0-0.4-0.4-0.8-0.8-0.8H19.4c-0.4,0-0.8,0.4-0.8,0.8v1
                                C18.6,15,19,15.3,19.4,15.3z"/>
                            <path class="st0" d="M17.7,26.1h14.7c0.4,0,0.8-0.4,0.8-0.8v-7.5c0-0.4-0.4-0.8-0.8-0.8H17.7c-0.4,0-0.8,0.4-0.8,0.8v7.5
                                C16.9,25.7,17.2,26.1,17.7,26.1z M18.5,18.5h13.1v5.9H18.5V18.5z"/>
                            <path class="st0" d="M37.7,16.4h-0.8v-2.3c0-2.1-1.7-3.8-3.8-3.8H16.9c-2.1,0-3.8,1.7-3.8,3.8v2.3h-0.8c-0.8,0-1.3,0.6-1.3,1.3v5.6
                                c0,0.8,0.6,1.3,1.3,1.3h0.8v9.7c0,0.4,0.4,0.8,0.8,0.8h0.2v0.8c0,0.8,0.7,1.5,1.5,1.5h3.1c0.4,0,0.8-0.2,1.1-0.5
                                c0.2-0.3,0.4-0.7,0.3-1v-0.8h9.5v0.8c0,0.8,0.7,1.5,1.5,1.5h3.1c0.4,0,0.8-0.2,1.1-0.5c0.2-0.3,0.4-0.7,0.3-1v-0.8h0.4
                                c0.4,0,0.8-0.4,0.8-0.8v-9.7h0.8c0.7,0,1.3-0.6,1.3-1.4v-5.5C39,16.9,38.4,16.4,37.7,16.4z M14.7,14.1c0-1.2,1-2.2,2.2-2.2h16.1
                                c1.2,0,2.2,1,2.2,2.2v3.1v6.6v9.7h-0.4h-4.4H19.4h-4.4h-0.2v-9.7v-6.6V14.1z M12.6,23v-5h0.5v5H12.6z M18.6,35.8h-2.9v-0.7h2.9
                                V35.8z M34.1,35.8h-2.9v-0.7h2.9V35.8z M37.4,23h-0.5v-5h0.5V23z"/>
                            <path class="st0" d="M19.4,27.6c-1.3,0-2.3,1-2.3,2.3s1,2.3,2.3,2.3c1.3,0,2.3-1,2.3-2.3S20.7,27.6,19.4,27.6z M19.4,30.6
                                c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7S19.8,30.6,19.4,30.6z"/>
                            <path class="st0" d="M30.5,27.6c-1.3,0-2.3,1-2.3,2.3s1,2.3,2.3,2.3s2.3-1,2.3-2.3S31.8,27.6,30.5,27.6z M30.5,30.6
                                c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7s0.7,0.3,0.7,0.7S30.9,30.6,30.5,30.6z"/>
                        </g>
                    </svg>
                </label>
                <label for="busStop06" class="busTimeTable__point busStop06 linkElm" v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="busModelOpen($event, 'busStop06')">
                    <svg version="1.1" x="0px" y="0px" viewBox="0 0 50 75">
                        <path id="pin-icon" d="M25,0C11.9,0,0,10.6,0,23.8C0,43.3,19.9,45.3,25,75c5.1-29.7,25-31.7,25-51.2C50,10.6,38.1,0,25,0z"/>
                        <g id="bus-icon">
                            <path class="st0" d="M19.4,15.3h11.1c0.4,0,0.8-0.4,0.8-0.8v-1c0-0.4-0.4-0.8-0.8-0.8H19.4c-0.4,0-0.8,0.4-0.8,0.8v1
                                C18.6,15,19,15.3,19.4,15.3z"/>
                            <path class="st0" d="M17.7,26.1h14.7c0.4,0,0.8-0.4,0.8-0.8v-7.5c0-0.4-0.4-0.8-0.8-0.8H17.7c-0.4,0-0.8,0.4-0.8,0.8v7.5
                                C16.9,25.7,17.2,26.1,17.7,26.1z M18.5,18.5h13.1v5.9H18.5V18.5z"/>
                            <path class="st0" d="M37.7,16.4h-0.8v-2.3c0-2.1-1.7-3.8-3.8-3.8H16.9c-2.1,0-3.8,1.7-3.8,3.8v2.3h-0.8c-0.8,0-1.3,0.6-1.3,1.3v5.6
                                c0,0.8,0.6,1.3,1.3,1.3h0.8v9.7c0,0.4,0.4,0.8,0.8,0.8h0.2v0.8c0,0.8,0.7,1.5,1.5,1.5h3.1c0.4,0,0.8-0.2,1.1-0.5
                                c0.2-0.3,0.4-0.7,0.3-1v-0.8h9.5v0.8c0,0.8,0.7,1.5,1.5,1.5h3.1c0.4,0,0.8-0.2,1.1-0.5c0.2-0.3,0.4-0.7,0.3-1v-0.8h0.4
                                c0.4,0,0.8-0.4,0.8-0.8v-9.7h0.8c0.7,0,1.3-0.6,1.3-1.4v-5.5C39,16.9,38.4,16.4,37.7,16.4z M14.7,14.1c0-1.2,1-2.2,2.2-2.2h16.1
                                c1.2,0,2.2,1,2.2,2.2v3.1v6.6v9.7h-0.4h-4.4H19.4h-4.4h-0.2v-9.7v-6.6V14.1z M12.6,23v-5h0.5v5H12.6z M18.6,35.8h-2.9v-0.7h2.9
                                V35.8z M34.1,35.8h-2.9v-0.7h2.9V35.8z M37.4,23h-0.5v-5h0.5V23z"/>
                            <path class="st0" d="M19.4,27.6c-1.3,0-2.3,1-2.3,2.3s1,2.3,2.3,2.3c1.3,0,2.3-1,2.3-2.3S20.7,27.6,19.4,27.6z M19.4,30.6
                                c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7S19.8,30.6,19.4,30.6z"/>
                            <path class="st0" d="M30.5,27.6c-1.3,0-2.3,1-2.3,2.3s1,2.3,2.3,2.3s2.3-1,2.3-2.3S31.8,27.6,30.5,27.6z M30.5,30.6
                                c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7s0.7,0.3,0.7,0.7S30.9,30.6,30.5,30.6z"/>
                        </g>
                    </svg>
                </label>
            </div>
        </div>
        <div class="busTimeTable__select--box">
            <input class="busStop__radio" type="radio" v-model="selectBusStop" name="selectBusStop" id="busStop01" value="busStop01" v-on:touchstart="touchOn" v-on:touchend="busModelOpen($event, value)">
            <label for="busStop01" class="busTimeTable__select--list busStop01 linkElm" v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="busModelOpen($event, 'busStop01')">
                文化公園<br>(1番のりば)
            </label>
            <input class="busStop__radio" type="radio" v-model="selectBusStop" name="selectBusStop" id="busStop02" value="busStop02" v-on:touchstart="touchOn" v-on:touchend="busModelOpen($event, value)">
            <label for="busStop02" class="busTimeTable__select--list busStop02 linkElm" v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="busModelOpen($event, 'busStop02')">
                文化公園前<br>(1番のりば)
            </label>
            <input class="busStop__radio" type="radio" v-model="selectBusStop" name="selectBusStop" id="busStop03" value="busStop03" v-on:touchstart="touchOn" v-on:touchend="busModelOpen($event, value)">
            <label for="busStop03" class="busTimeTable__select--list busStop03 linkElm" v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="busModelOpen($event, 'busStop03')">
                文化公園前<br>(2番のりば)
            </label>
            <input class="busStop__radio" type="radio" v-model="selectBusStop" name="selectBusStop" id="busStop04" value="busStop04" v-on:touchstart="touchOn" v-on:touchend="busModelOpen($event, value)">
            <label for="busStop04" class="busTimeTable__select--list busStop04 linkElm" v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="busModelOpen($event, 'busStop04')">
                霧島4丁目<br>(1番のりば)
            </label>
            <input class="busStop__radio" type="radio" v-model="selectBusStop" name="selectBusStop" id="busStop05" value="busStop05" v-on:touchstart="touchOn" v-on:touchend="busModelOpen($event, value)">
            <label for="busStop05" class="busTimeTable__select--list busStop05 linkElm" v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="busModelOpen($event, 'busStop05')">
                霧島4丁目<br>(2番のりば)
            </label>
            <input class="busStop__radio" type="radio" v-model="selectBusStop" name="selectBusStop" id="busStop06" value="busStop06" v-on:touchstart="touchOn" v-on:touchend="busModelOpen($event, value)">
            <label for="busStop06" class="busTimeTable__select--list busStop06 linkElm" v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="busModelOpen($event, 'busStop06')">
                宮崎神宮<br>(2番のりば)
            </label>
        </div>
    </div>
</template>

<script>
import { touchEvent, linkEvent } from '../mixin02.js'
export default {
    name: 'busTimeTable',
    data() {
        return{
            selectBusStop: "",
            modalToggle: false,
            busData: {
                busStop01: {
                    id: "01",
                    data_name: "busStop01",
                    name: "文化公園(1番のりば)",
                    time_table: {
                        weekday: "img/11bus/busStop01_weekday01-min.jpg",
                        saturday: "img/11bus/busStop01_holiday01-min.jpg",
                        holiday: "img/11bus/busStop01_holiday01-min.jpg",
                        qr: "img/14qr/qr_bunkakouen.png",
                    },
                    tiem_table_slider: false,
                },
                busStop02: {
                    id: "02",
                    data_name: "busStop02",
                    name: "文化公園前(1番のりば)",
                    time_table: {
                        weekday: "img/11bus/busStop02_weekday01-min.jpg",
                        saturday: "img/11bus/busStop02_holiday01-min.jpg",
                        holiday: "img/11bus/busStop02_holiday01-min.jpg",
                        qr: "img/14qr/qr_bunkakouennmae.png",
                    },
                    tiem_table_slider: false,
                },
                busStop03: {
                    id: "03",
                    data_name: "busStop03",
                    name: "文化公園前(2番のりば)",
                    time_table: {
                        weekday: "img/11bus/busStop03_weekday01-min.jpg",
                        saturday: "img/11bus/busStop03_holiday01-min.jpg",
                        holiday: "img/11bus/busStop03_holiday01-min.jpg",
                        qr: "img/14qr/qr_bunkakouennmae.png",
                    },
                    tiem_table_slider: false,
                },
                busStop04: {
                    id: "04",
                    data_name: "busStop04",
                    name: "霧島4丁目(1番のりば)",
                    time_table: {
                        weekday: "img/11bus/busStop04_weekday01-min.jpg",
                        saturday: "img/11bus/busStop04_holiday01-min.jpg",
                        holiday: "img/11bus/busStop04_holiday01-min.jpg",
                        qr: "img/14qr/qr_kirishima4.png",
                    },
                    tiem_table_slider: false,
                },
                busStop05: {
                    id: "05",
                    data_name: "busStop05",
                    name: "霧島4丁目(2番のりば)",
                    time_table: {
                        weekday: "img/11bus/busStop05_weekday01-min.jpg",
                        saturday: "img/11bus/busStop05_holiday01-min.jpg",
                        holiday: "img/11bus/busStop05_holiday01-min.jpg",
                        qr: "img/14qr/qr_kirishima4.png",
                    },
                    tiem_table_slider: false,
                },
                busStop06: {
                    id: "06",
                    data_name: "busStop06",
                    name: "宮崎神宮(2番のりば)",
                    time_table: {
                        weekday: ["img/11bus/busStop06_weekday01-min.jpg","img/11bus/busStop06_weekday02-min.jpg"],
                        saturday: ["img/11bus/busStop06_saturday01-min.jpg","img/11bus/busStop06_saturday02-min.jpg"],
                        holiday: ["img/11bus/busStop06_holiday01-min.jpg","img/11bus/busStop06_holiday02-min.jpg"],
                        qr: "img/14qr/qr_miyazakijingu.png",
                    },
                    tiem_table_slider: true,
                },
            }
        }
    },
    mixins: [touchEvent, linkEvent],
    methods: {
        busModelOpen($event, item){
            if(!this.touchEnd($event)) return;
            setTimeout(() => {
                if(item) this.selectBusStop = item;
                this.modalToggle = true;
                this.$emit('busModalOpen', this.modalToggle, this.busData[this.selectBusStop]);
            }, 10);
        },
    },
    created: function() {

    },
    mounted() {
    }
}

</script>

<style>
/* バス時刻表コンポーネント */
.busTimeTable {

}
.busTimeTable__title {
    font-size: 3.0rem;
    font-weight: 600;
    text-align: center;
    color: var(--mainColor);
}
.busTimeTable__map--box {
    margin: 40px 0 0 0;
    position: relative;
    border: solid 3px #EBEBEB;
    border-radius: 5px;
    overflow: hidden;
}
.busTimeTable__map {
    width: 100%;
}
.busTimeTable__map img {
    width: 100%;
    height: auto;
}
.busTimeTable__points--box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.busTimeTable__point {
    display: block;
    width: 50px;
    height: 75px;
    position: absolute;
    transform: translate(-50%,-100%);
}
:root {
    --busStop01: #096FC8;
    --busStop02: #E55643;
    --busStop03: #EC964B;
    --busStop04: #2E8E72;
    --busStop05: #A2C761;
    --busStop06: #1E6088;
}
.busTimeTable__point.busStop01 {
    top: 36%;
    left: 56.6%;
}
.busTimeTable__point.busStop02 {
    top: 43%;
    left: 60%;
}
.busTimeTable__point.busStop03 {
    top: 53%;
    left: 62%;
}
.busTimeTable__point.busStop04 {
    top: 34%;
    left: 9%;
}
.busTimeTable__point.busStop05 {
    top: 18%;
    left: 7%;
}
.busTimeTable__point.busStop06 {
    top: 41%;
    left: 91%;
}
.busTimeTable__point svg #bus-icon {
    fill: #fff;
}
.busTimeTable__point.busStop01 svg #pin-icon {
    fill: var(--busStop01);
}
.busTimeTable__point.busStop02 svg #pin-icon {
    fill: var(--busStop02);
}
.busTimeTable__point.busStop03 svg #pin-icon {
    fill: var(--busStop03);
}
.busTimeTable__point.busStop04 svg #pin-icon {
    fill: var(--busStop04);
}
.busTimeTable__point.busStop05 svg #pin-icon {
    fill: var(--busStop05);
}
.busTimeTable__point.busStop06 svg #pin-icon {
    fill: var(--busStop06);
}
.busTimeTable__select--box {
    margin: 40px auto 0;
    padding: 60px 100px;
    border: solid 3px var(--mainColor);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    width: 650px;
    gap: 30px 30px;
    flex-wrap: wrap;
}
.busStop__radio {
    display: none;
}
.busTimeTable__select--list {
    width: 125px;
    height: 125px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.3;
    font-weight: 600;
    color: #fff;
}
.busTimeTable__select--list.busStop01 {
    background-color: var(--busStop01);
}
.busTimeTable__select--list.busStop02 {
    background-color: var(--busStop02);
}
.busTimeTable__select--list.busStop03 {
    background-color: var(--busStop03);
}
.busTimeTable__select--list.busStop04 {
    background-color: var(--busStop04);
}
.busTimeTable__select--list.busStop05 {
    background-color: var(--busStop05);
}
.busTimeTable__select--list.busStop06 {
    background-color: var(--busStop06);
}


</style>