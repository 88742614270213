<template>
    <div ref="zoom" class="zoom" v-if="val">
        <div ref="zoom__elm" class="zoom__elm" v-html="val"></div>
    </div>
</template>

<script>
import panzoom from '../panzoom/'
export default {
    name: "zoomVue02",
    data() {
        return{
            zoomElm: document.querySelector(".zoom__elm"),
            panZoom: null,
        }
    },
    props: ['val', 'toggle'],
    watch: {
        toggle: function(newval) {
            if(newval) this.zoomOn()
        },
    },
    methods: {
        zoomOn() {
            setTimeout(() => {
                this.zoomElm = document.querySelector(".zoom__elm");
                this.panZoom = panzoom(this.zoomElm, {
                    pinchSpeed: 1.0,
                    maxZoom: 3.0,
                    minZoom: 1.0,
                    bounds: true,
                    boundsPadding: 1.0,
                    initialX: 0,
                    initialY: 0,
                    initialZoom: 1, 
                });
            }, 10);
        },
        zoomOff() {
            if(this.panZoom){
                // this.panZoom.pause();
                // this.panZoom.zoomTo(0, 0, 0);
                this.panZoom.dispose();
            }
        }
    },
    created() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    beforeDestroy() {
        this.zoomOff();
    },
    destroyed() {
        
    }
}
</script>

<style>
.zoom {
    position: relative;
    /* position: absolute; */
    /* top: 0; */
    /* left: 0; */
    overflow: hidden;
    width: 100%;
    height: 100%;
    outline: none;
}
.zoom__elm {
    width: 100%;
    height: 100%;
    outline: none;
    transform-origin: 0 0;
}

.zoom::selection,
.zoom__elm::selection,
.zoom__elm img::selection{
    background: none;
}

.zoom__elm img {
    display: block;
    pointer-events: none;
    outline: none;
}

</style>









