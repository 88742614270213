<template>
    <div v-on:touchend.self="closeModal" class="overlay">
        <div class="mainContent__list topNewsModal">
            <div class="mainContent__list--head modal">
                本日のニュース
                <div v-on:touchend="closeModal" class="modal__close--btn linkElm" v-on:touchstart="addClass($event)">
                    <span class="modal__close--btn--inner modal__close--btn--inner01"></span>
                    <span class="modal__close--btn--inner modal__close--btn--inner02"></span>
                </div>
            </div>
            <div class="mainContent__list--content--outer--model">
                <div class="topNews__content">
                    <div class="topNews__content--right">
                        <div class="topNews__content--right--inner">
                            <div v-html="val.title" class="topNews__content--title modal"></div>
                            <div v-html="val.content" class="topNews__content--text modal"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { linkEvent } from '../mixin02.js'
export default {
    name: 'topNewsModal',
    data() {
        return {
            ModalToggle: false,
        }
    },
    mixins: [linkEvent],
    props: ['val'],
    created() {
    },
    methods: {
        closeModal() {
            this.$emit('topNewsModalClose', this.ModalToggle);
        }
    }
}
</script>
<style>
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 1080px;
    min-width: 100%;
    height: 1920px;
    min-height: 100%;
    background-color: rgba(0,0,0,0.3);
}
.topNewsModal {
    width: 90% !important;
    height: auto;
    min-width: 1000px;
    background-color: #fff;
    border-radius: 5px;
    position: absolute;
    top: 625px;
    left: 50%;
    transform: translate(-50%,0);
}
.topNews__content {

}
.topNews__content--right {
    width: 100%;
}
.topNews__content--right--inner {

}
.topNews__content--title.modal {
    font-size: 2.9rem;
    font-weight: 500;
}
.topNews__content--text.modal {
    font-size: 1.5rem;
    text-align: justify;
    margin: 20px 0 0 0;
    font-weight: 500;
}
.topNews__content--text.modal p {
    line-height: 1.5;
    margin: 0 0 0.8em;
}
</style>