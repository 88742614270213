<template>
    <div class="views__container">
        <div class="views__container--head">
            美術図書室
            <a v-on:touchstart="touchOn($event); addClass($event)" v-on:touchend="router($event, '/')" class="back__btn linkElm">
                <div class="back__btn--icon">
                <img class="back__btn--icon--img" src="../..//public/icon/svg/home.svg" alt="">
                </div>
                <div class="back__btn--title">TOPへ戻る</div>
            </a>
        </div>
        <div class="views__container--inner">
            <div class="minPage__container">
                <div class="minPage__block flex">
                    <div class="minPage__block--left">
                        <div class="minPage__block--left--img--box">
                            <img class="minPage__block--left--img" src="https://stdichigeki.com/signage/wp-content/uploads/2021/08/美術図書室01.jpeg" alt="">
                        </div>
                    </div>
                    <div class="minPage__block--right">
                        <div class="minPage__block--content">
                            <p class="minPage__block--content--p">
                                美術関係図書や画集、展覧会図録など約 4,500冊を備え、明るい雰囲気の中で自由に閲覧していただけます。
                                お探しの図書等がありましたら、サービスカウンターまでお気軽にお申し出ください。閉架の図書等についても、ご希望に応じて図書室内でご覧になれます。
                                様々な分野の美術雑誌や、美術関係の新聞記事、全国の美術館情報などもご覧いただけます。<br
                                >サービスカウンターでは、図書をはじめ、美術に関する様々な質問や相談にお答えします。
                            </p>
                            <div class="minPage__block--content--table--box">
                                <div class="minPage__block--content--table--outer">
                                    <div class="minPage__block--content--table--title">蔵書規模</div>
                                    <table class="minPage__block--content--table">
                                        <tr class="minPage__block--content--tr">
                                            <th class="minPage__block--content--th">種別</th>
                                            <th class="minPage__block--content--th">冊数</th>
                                        </tr>
                                        <tr class="minPage__block--content--tr">
                                            <th class="minPage__block--content--th">図書</th>
                                            <td class="minPage__block--content--td">10,356</td>
                                        </tr>
                                        <tr class="minPage__block--content--tr">
                                            <th class="minPage__block--content--th">図録等</th>
                                            <td class="minPage__block--content--td">10,568</td>
                                        </tr>
                                        <tr class="minPage__block--content--tr">
                                            <th class="minPage__block--content--th">合本雑誌等</th>
                                            <td class="minPage__block--content--td">&nbsp;&nbsp;1,447</td>
                                        </tr>
                                        <tr class="minPage__block--content--tr">
                                            <th class="minPage__block--content--th">計</th>
                                            <td class="minPage__block--content--td">22,371</td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="minPage__block--content--table--outer">
                                    <div class="minPage__block--content--table--title">配架状況</div>
                                    <table class="minPage__block--content--table">
                                        <tr class="minPage__block--content--tr">
                                            <th class="minPage__block--content--th">配架場所</th>
                                            <th class="minPage__block--content--th">冊数</th>
                                        </tr>
                                        <tr class="minPage__block--content--tr">
                                            <th class="minPage__block--content--th">開架(美術図書室)</th>
                                            <td class="minPage__block--content--td">&nbsp;&nbsp;&nbsp;4,546</td>
                                        </tr>
                                        <tr class="minPage__block--content--tr">
                                            <th class="minPage__block--content--th">閉架(資料室他)</th>
                                            <td class="minPage__block--content--td">17,825</td>
                                        </tr>
                                        <tr class="minPage__block--content--tr">
                                            <th class="minPage__block--content--th">計</th>
                                            <td class="minPage__block--content--td">22,371</td>
                                        </tr>
                                    </table>
                                    <div class="minPage__block--content--table--data">令和3年3月31日現在</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="minPage__block flex">
                    <div class="minPage__block--left">
                        <div class="minPage__block--title">ご利用案内</div>
                    </div>
                    <div class="minPage__block--right">
                        <div class="minPage__block--content">
                            <div class="minPage__block--content--dl--outer">
                                <dl class="minPage__block--content--dl">
                                    <dt class="minPage__block--content--dt wid140">
                                        利用時間
                                    </dt>
                                    <dd class="minPage__block--content--dd wid140">
                                        10:00～18:00
                                    </dd>
                                </dl>
                                <dl class="minPage__block--content--dl">
                                    <dt class="minPage__block--content--dt wid140">
                                        直通電話番号
                                    </dt>
                                    <dd class="minPage__block--content--dd wid140">
                                        0985-21-1685
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="minPage__block flex">
                    <div class="minPage__block--left">
                        <div class="minPage__block--title">特設コーナー</div>
                    </div>
                    <div class="minPage__block--right">
                        <div class="minPage__block--content">
                            <p class="minPage__block--content--p">
                                特設コーナーでは、郷土作家に関する図書や展覧会図録等をご覧いただけます。宮崎県を代表する画家、瑛九に関する図書等は、常時コーナー展示しています。特別展開催時には、展覧会の関連図書等をまとめてご覧になれます。
                            </p>
                            <p class="minPage__block--content--p">
                                また、こども向けの画集や技法書をまとめたコーナーもあります。
                            </p>
                        </div>
                    </div>
                </div>
                <div class="minPage__block flex">
                    <div class="minPage__block--left">
                        <div class="minPage__block--title">コピーサービス</div>
                    </div>
                    <div class="minPage__block--right">
                        <div class="minPage__block--content">
                            <p class="minPage__block--content--p">
                                利用される皆様の調査研究に役立てていただくため、美術館が所蔵する図書等の複写を行います(白黒コピーのみ)。ご希望の方は、 サービスカウンターまでお申し出ください。
                            </p>
                            <div class="minPage__block--content--dl--outer">
                                <dl class="minPage__block--content--dl">
                                    <dt class="minPage__block--content--dt wid140">
                                        受付時間
                                    </dt>
                                    <dd class="minPage__block--content--dd wid140">
                                        10:00～17:30
                                    </dd>
                                </dl>
                                <dl class="minPage__block--content--dl">
                                    <dt class="minPage__block--content--dt wid140">
                                        料金
                                    </dt>
                                    <dd class="minPage__block--content--dd wid140">
                                        1枚 10円
                                    </dd>
                                </dl>
                                <dl class="minPage__block--content--dl">
                                    <dt class="minPage__block--content--dt wid140">
                                        枚数
                                    </dt>
                                    <dd class="minPage__block--content--dd wid140">
                                        1箇所につき1部
                                    </dd>
                                </dl>
                            </div>
                            <p class="minPage__block--content--p">
                                ※複写は、著作権法で認められた範囲内で行います。以下の場合は複写をお断りしています。
                            </p>
                            <ul class="minPage__block--content--ul">
                                <li class="minPage__block--content--li">
                                    全体のページ数の半分を超える場合
                                </li>
                                <li class="minPage__block--content--li">
                                    作品図版、写真、地図等
                                </li>
                                <li class="minPage__block--content--li">
                                    次号が未発行の新刊雑誌等
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import router from '../router'
import { touchEvent, linkEvent } from '../mixin02.js'
export default {
    data() {
        return {

        }
    },
    mixins: [touchEvent, linkEvent],
    methods: {
        router($event, path) {
            if(!this.touchEnd($event)) return;
            router.push(path);
        },
    },
}
</script>

<style>
.minPage__block--left--img--box {
    width: 100%;
    height: auto;
}
.minPage__block--left--img {
    width: 100%;
    height: auto;
}
.minPage__block--content--table--box {
    display: flex;
    justify-content: space-between;
    margin: 40px 0 0 0;
}
.minPage__block--content--table--outer {

}
.minPage__block--content--table--title {

}
.minPage__block--content--table {
    text-align: center;
    vertical-align: center;
    border-collapse: collapse;
    margin: 8px 0 0 0;
}
.minPage__block--content--tr {
    height: 40px;
}
.minPage__block--content--th {
    width: 160px;
    background-color: #DEDEDE;
    border: solid 1px #EEE; 
}
.minPage__block--content--td {
    width: 160px;
    background-color: #FAFAFA;
}
.minPage__block--content--table--data {
    text-align: center;
    width: 100%;
    background-color: #DEDEDE;
    padding: 9px 0 6px;
    margin: 10px 0 0 0;
}


</style>