<template>
  <div class="home">
    <div class="signage">
        <div class="signage--inner">
            <!-- <header></header> -->
            <main class="homeMain">
                <div class="homeSection--outer">
                    <section class="headAdArea__section">
                        <topMovieArea ref="topMovieArea" v-on:keepDataExport="keepDataExport" v-on:fullAddModalOpen02="fullAddModalOpen" v-bind:val="videoRestartToggle"></topMovieArea>
                    </section>
                    <section class="mainContent__section">
                        <div class="mainContent">
                            <div class="mainContent__container">
                                <div class="mainContent__container--inner mainContent__container--left">
                                    <div class="mainContent__list mainContent__list--guest">
                                      <topGuest v-on:guestModalOpen="guestModalOpen"></topGuest>
                                    </div>
                                    <div class="mainContent__list mainContent__list--news">
                                      <topNews v-on:topNewsModalOpen="topNewsModalOpen"></topNews>
                                    </div>
                                    <div class="mainContent__list mainContent__list--skd">
                                      <topSchedule v-on:scheduleModalOpen="topScheduleOpen"></topSchedule>
                                    </div>
                                    <div class="mainContent__list mainContent__list--ic">
                                      <topInfectionControl></topInfectionControl>
                                    </div>
                                </div>
                                <div class="mainContent__container--inner mainContent__container--right">
                                    <div class="mainContent__list mainContent__list--nav">
                                      <topNav></topNav>
                                    </div>
                                    <div class="mainContent__list mainContent__list--products">
                                      <topGoodsList v-on:goodsModalOpen="topGoodsOpen"></topGoodsList>
                                    </div>
                                    <div class="mainContent__list mainContent__list--gameContent">
                                      <topGameContent v-on:gameContentModalOpen="gameContentModalOpen"></topGameContent>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
            <!-- <footer></footer> -->
        </div>
    </div>
    <div class="top__overlay" v-bind:class="{close: topOverlayClose}" v-on:touchend="topOverlayOff">
        <div class="top__overlay--img">
            <img src="../../public/icon/svg/interactiveMovie.svg" alt="">
        </div>
        <div class="top__overlay--text">
            画面をタッチして操作を開始してください
        </div>
    </div>
    <!-- modal -->
    <topNewsModal v-show="topNewsModalToggle" v-on:topNewsModalClose="topNewsModalClose" v-bind:val="newsData"></topNewsModal>
    <topScheduleModal v-on:scheduleModalClose="topScheduleClose" v-bind:val="scheduleData" v-show="topScheduleToggle" ></topScheduleModal>
    <topGoodsModal v-on:GoodsModalClose="topGoodsClose" v-bind:val="GoodsData" v-bind:toggle="topGoodsToggle" v-show="topGoodsToggle" ></topGoodsModal>
    <!-- <fullPageAdd v-on:addModalClose="addModalClose" v-show="fullPageAddToggle" v-bind:val="fullPageAddToggle"></fullPageAdd> -->
    <fullPageAdd02 v-on:addModalClose="addModalClose" v-show="fullPageAddToggle" v-bind:val="fullPageAddToggle"></fullPageAdd02>
    <guestModal v-show="guestModalToggle" v-on:guestModalClose="guestModalClose"></guestModal>
    <gameContentModal v-show="gameContentModalToggle" v-on:gameContentModalClose="gameContentModalClose"></gameContentModal>
    <!-- modal end -->
  </div>
</template>

<script>
import topMovieArea from '../components/topMovieArea.vue'
import topGuest from '../components/topGuest.vue'
import topNews from '../components/topNews.vue'
import topSchedule from '../components/topSchedule.vue'
import topInfectionControl from '../components/topInfectionControl.vue'
import topNav from '../components/topNav.vue'
import topGoodsList from '../components/topGoodsList.vue'
import topGameContent from '../components/topGameContent.vue'

// modal
import topNewsModal from '../components/topNewsModal'
import topScheduleModal from '../components/topScheduleModal'
import topGoodsModal from '../components/topGoodsModal'
// import fullPageAdd from '../components/fullPageAdd'
import fullPageAdd02 from '../components/fullPageAdd02'
import guestModal from '../components/guestModal'
import gameContentModal from '../components/gameContentModal'

export default {
    name: 'HOME',
    data() {
        return{
            // modal (スケジュール)
            newsData: "",
            topNewsModalToggle: false,
            // modal (スケジュール)
            topScheduleToggle: false,
            scheduleData: "",
            // modal (グッズ)
            topGoodsToggle: false,
            GoodsData: "",
            // modal (全面広告)
            fullPageAddToggle: false,
            videoRestartToggle: false,
            // modal (ゲスト)
            guestModalToggle: false,
            // modal (ゲーム)
            gameContentModalToggle: false,

            clickAction: false,
            topOverlayClose: true,
        }
    },
    watch: {
    },
    components: {
        topMovieArea,
        topGuest,
        topNews,
        topSchedule,
        topInfectionControl,
        topNav,
        topGoodsList,
        topGameContent,

        // modal
        topNewsModal,
        topScheduleModal,
        topGoodsModal,
        // fullPageAdd,
        fullPageAdd02,
        guestModal,
        gameContentModal,
    },
    methods: {
        // topMovieエリア
        keepDataExport(val) {
            this.$emit('keepDataExport', val);
        },
        keepDataInport(val) {
            this.$refs.topMovieArea.keepDataInport(val);
        },
        // modal (ニュース)
        topNewsModalOpen(toggle, newsData) {
            this.topNewsModalToggle = toggle;
            this.newsData = newsData;
        },
        topNewsModalClose(toggle) {
            this.topNewsModalToggle = toggle;
        },
        // modal (スケジュール)
        topScheduleOpen(toggle, scheduleData) {
            this.topScheduleToggle = toggle;
            this.scheduleData = scheduleData;
        },
        topScheduleClose(toggle) {
            this.topScheduleToggle = toggle;
        },
        // modal (グッズ)
        topGoodsOpen(toggle, GoodsData) {
            this.topGoodsToggle = toggle;
            this.GoodsData = GoodsData;
        },
        topGoodsClose(toggle) {
            this.topGoodsToggle = toggle;
        },
        // modal (ゲスト)
        guestModalOpen(toggle) {
            this.guestModalToggle = toggle;
        },
        guestModalClose(toggle) {
            this.guestModalToggle = toggle;
        },
        // modal (ゲーム)
        gameContentModalOpen(toggle) {
            this.gameContentModalToggle = toggle;
        },
        gameContentModalClose(toggle) {
            this.gameContentModalToggle = toggle;
        },
        // modal (全面広告)
        fullAddModalOpen(toggle) {
            this.fullPageAddToggle = toggle;
            this.videoRestartToggle = !toggle;
            this.topScheduleClose(!toggle);
            this.topGoodsClose(!toggle);
            this.guestModalClose(!toggle);
            this.gameContentModalClose(!toggle)
            this.topOverlayOn();
        },
        addModalClose(toggle){
            this.fullPageAddToggle = toggle;
            this.videoRestartToggle = !toggle;
        },
        // タッチしてください
        topOverlayOff() {
            this.topOverlayClose = true;
        },
        topOverlayOn() {
            this.topOverlayClose = false;
        },
    },
    created() {
        var mainScript = document.createElement('script');
        mainScript.src = './js/main.js';
        document.body.appendChild(mainScript);
    }
}

</script>

<style>
/******************************************
 全体の構成
******************************************/
.homeMain {
    width: 100%;
    height: 100%;
}
.homeSection--outer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

/******************************************
 クリックアニメーション
******************************************/
.linkElm {
    overflow: hidden;
    position: relative;
}
.linkElm::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    opacity: 0;
    transition: 0.3s ease-out;
}
.linkElm.active::after {
    transition: none;
    opacity: 0.5;
}



/******************************************
 メインコンテンツ(共通部分)
******************************************/
.mainContent__section {
    width: 100%;
    /* height: 62.135417%; */
    height: 1180px;
}
.mainContent {
    width: 100%;
    height: 100%;
}
.mainContent__container {
    width: 100%;
    height: 100%;
    padding: 30px 20px;
    display: flex;
    gap: 30px 20px;
}
.mainContent__container--inner {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px 0;
}
.mainContent__list {
    width: 100%;
    background-color: var(--listBgColor);
    border-radius: 5px;
    overflow: hidden;
    filter: drop-shadow(0 2px 8px rgba(0,0,0,0.10) );
}
.mainContent__list--head {
    width: 100%;
    height: 58px;
    background-color: var(--mainColor);
    color: var(--headerTextColor);
    text-align: center;
    line-height: 58px;
    font-size: 2.5rem;
    font-weight: 600;
}
.mainContent__list--content--outer {
    width: 100%;
    height: calc(100% - 58px);
    padding: 12px 20px 0 20px;
}
.mainContent__list--content {

}
/* スクロールバー変更用 */
.mainContent__list--components {
    overflow: visible;
    height: 100%;
}
.mainContent__list--content--outer--scroll {
    padding: 0 4px 0 0;
    position: relative;
}
.mainContent__list--content--outer--scrollBar {
    width: 100%;
    height: 100%;
    padding: 12px 16px 0 20px;
    overflow-y: overlay;
}
.mainContent__list--content--outer--scrollBar::-webkit-scrollbar {
    width: 8px;
}
.mainContent__list--content--outer--scrollBar::-webkit-scrollbar-track {
    margin: 3px 0;
}
.mainContent__list--content--outer--scrollBar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #D3D3D3;
}

/******************************************
 メインコンテンツ()
******************************************/
/**************** left ****************/
/* 来場者数 */
.mainContent__list--guest {
    height: 7.5%;
}
/* 本日のニュース */
.mainContent__list--news {
    height: 26.5%;
}
/* 本日のスケジュール */
.mainContent__list--skd {
    height: 46%;
}
/* 感染対策 */
.mainContent__list--ic {
    height: 20%;
}

/**************** right ****************/
/* ナビゲーション */
.mainContent__list--nav {
    height: 33%;
}
/* グッズ一覧 */
.mainContent__list--products {
    height: 47%;
}
/* コンテンツ */
.mainContent__list--gameContent {
    height: 20%;
}

/******************************************
 フッターナビ
******************************************/
.footerNav__section {
    width: 100%;
    /* height: 6.354167%; */
    height: 132px;
}
.footerNav {
    width: 100%;
    height: 100%;
    background-color: #fff;
}
.footerNav__container {
    display: flex;
    justify-content: space-between;
}
.footerNav__content {
    width: calc(100% - 132px);
    display: flex;
    align-items: center;
}
.footerNav__content--inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 35px;
    transform: translate(0,0);
}
.footerNav__list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    width: 80px;
    color: var(--mainColor);
}
.footerNav__list--icon--outer {
    background-color: var(--mainColor) !important;
    width: 100%;
    position: relative;
    border-radius: 10px;
}
.footerNav__list--icon--outer::before {
    content: "";   
    display: block;
    width: 100%;
    padding-top: 100%;
}
.footerNav__list--icon--flex {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.footerNav__list--icon {
    width: 80%;
    position: relative;
    overflow: hidden;
}
.footerNav__list--icon::before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 100%;
}
.footerNav__list--icon--img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
}
.footerNav__list--title {
    font-size: 1.2rem;
    font-weight: 500;
    white-space: nowrap;
}
.footerNav__home--btn {
    width: 132px;
    height: 132px;
    background-color: var(--mainColor);
    color: var(--headerTextColor);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 1px;
}
.footerNav__home--btn--icon {
    width: 70%;
    position: relative;
}
.footerNav__home--btn--icon::before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 100%;
}
.footerNav__home--btn--icon--img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
}
.footerNav__home--btn--title {
    font-size: 2.1rem;
}


/******************************************
 viewページ共通
******************************************/
.views__container--head {
    width: 100%;
    height: 180px;
    background-color: var(--mainColor);
    color: var(--headerTextColor);
    font-size: 5.0rem;
    font-weight: 600;
    text-align: center;
    line-height: 180px;
    position: relative;
    z-index: 5;
    overflow: hidden;
}
.views__container--inner {
    width: 100%;
    height: calc(100% - 180px);
}

/* back__btn */
.back__btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 180px;
  height: 180px;
  border-left: solid 3px #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.back__btn--icon {
    width: 130px;
    height: auto;
}
.back__btn--icon--img {
    width: 100%;
    height: auto;
    display: block;
}
.back__btn--title {
    font-size: 2.0rem;
    color: #fff;
    padding: 0 0 10px 0;
}

.top__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 50;
    width: 100%;
    height: calc(100% - 608px);
    background-color: rgba(0,0,0,0.45);
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 50px 0;
    /* transition: all 0.5s ease-out; */
}
.top__overlay.close {
    opacity: 0;
    display: none;
}
.top__overlay--img {
    width: 300px;
    position: relative;
    opacity: 0.9;
    margin: 0 0 190px 0;
}
.top__overlay--img::before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 100%;
}
.top__overlay--img img {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: contain;
}
.top__overlay--text {
    text-align: center;
    color: #fff;
    font-weight: 600;
    font-size: 3.5rem;
    opacity: 0.95;
    filter: drop-shadow(0px 0px 35px #000);
    /* margin: 0 0 200px 0; */
}
</style>